import { BsGearFill } from "react-icons/bs";

export default function GearLoader(props: { color: string; large?: boolean }) {
  return (
    <div style={{ position: "relative", width: 45, height: 35, transform: props.large? "scale(1.5)" : "scale(1)" }}>
      <BsGearFill
        className="gear-spin-1"
        size={20}
        color={props.color}
        style={{ position: "absolute", top: 0, right: 0 }}
      />
      <BsGearFill
        className="gear-spin-2"
        size={30}
        color={props.color}
        style={{ position: "absolute", bottom: 0, left: 0 }}
      />
    </div>
  );
}
