import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

type WaitlistSignupProps = {
  deviceLarge: boolean;
  confirm: boolean;
  env: string;
};

function WaitlistSignup({ deviceLarge, confirm, env }: WaitlistSignupProps) {
  const [planSelected, setPlanSelected] = useState(1);
  const [displayedPrice, setDisplayedPrice] = useState<{
    [key: string]: number;
  }>({ "1": 27.99, "12": 25.99 });
  const [source, setSource] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [promoCodeId, setPromoCodeId] = useState<null | string>(null);
  const [childname, setChildname] = useState("");
  const [totalCost, setTotalCost] = useState("0");
  const [discountEntryOpen, setDiscountEntryOpen] = useState(false);
  const [discountErrorMessage, setDiscountErrormessage] = useState("");
  const [discountPercentOff, setDiscountPercentOff] = useState<null | number>(
    null
  );
  const [discountAmountOff, setDiscountAmountOff] = useState<null | number>(
    null
  );

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [postcode, setPostcode] = useState("");

  const discount = 0.075;

  // useEffect(() => {
  //   const price = localStorage.getItem("price");

  //   if (price) {
  //     const intPrice = JSON.parse(price);
  //     setAssignedPrice(intPrice);
  //     setDisplayedPrice(intPrice);
  //   } else {
  //     const currentD = new Date();
  //     const currentMinutes = currentD.getMinutes();
  //     const aPrice =
  //       testPrices[Math.floor((currentMinutes * testPrices.length) / 60)];
  //     setAssignedPrice(aPrice);
  //     setDisplayedPrice(aPrice);
  //     localStorage.setItem("price", JSON.stringify(aPrice));
  //   }

  //   if (confirm) {
  //     document.title = "Thank you!";
  //   } else {
  //     document.title = "Signup to Team Repair";
  //   }
  // }, []);

  const SERVER_URL = "https://api.teamrepair.dev";

  function reloadTotalCost() {
    if (discountAmountOff) {
      setTotalCost(
        (
          displayedPrice[planSelected.toString()] * planSelected -
          discountAmountOff
        ).toFixed(2)
      );
    } else if (discountPercentOff) {
      setTotalCost(
        (
          displayedPrice[planSelected.toString()] *
          planSelected *
          ((100 - discountPercentOff) / 100)
        ).toFixed(2)
      );
    } else {
      setTotalCost(
        (displayedPrice[planSelected.toString()] * planSelected).toFixed(2)
      );
    }
  }

  useEffect(() => {
    reloadTotalCost();
  }, [planSelected, discountAmountOff, discountPercentOff, displayedPrice]);

  function checkDiscountCode(code?: string) {
    let codeToCheck;
    if (code) {
      codeToCheck = code;
    } else {
      codeToCheck = discountCode;
    }
    if (codeToCheck === "") {
      setPromoCodeId(null);
      return false;
    }
    fetch(SERVER_URL + "/web/verifycouponcode", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: codeToCheck,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setPromoCodeId(data["promo_code_id"]);
          if (data["percent_off"] != null) {
            setDiscountPercentOff(data["percent_off"]);
          } else if (data["amount_off"] != null) {
            setDiscountAmountOff(data["amount_off"] / 100);
          } else {
            setPromoCodeId(null);
            setDiscountErrormessage("Something went wrong, please try again.");
          }
          return reloadTotalCost();
        } else if (data["error"]) {
          setDiscountErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        err.status
          ? setDiscountErrormessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setDiscountErrormessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  return (
    <>
      {!confirm ? (
        <form
          action="https://repair.us20.list-manage.com/subscribe/post?u=48a950bb4c024915921a41b89&amp;id=c2bd85b8fd&amp;f_id=008c13e7f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate signup-container"
          // target="_blank"
        >
          <div>
            <div className="navbar-spacing"></div>

            <h1 className="page-title blue padded">
              Ready to fix the future? Join our waitlist!
            </h1>

            <div className="signup-block">
              <h2 className="block-header">Enter your child's details:</h2>
              <input
                type="text"
                name="CNAME"
                placeholder="Child's nickname"
                id="mce-CNAME"
                onChange={(e) => {
                  setChildname(e.target.value);
                  localStorage.setItem("cname", e.target.value);
                }}
              />
              <div className="age-cont">
                <label>Child's age: </label>
                <select name="AGE" id="mce-AGE" className="select minimal">
                  <option value="">Select</option>
                  <option value="&lt;5">&lt;5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16+">16+</option>
                </select>
              </div>
            </div>
            <div className="box-block">
              <img src="hero.jpg" alt="" className="box-image" />
              <div className={`box-name ${childname.length > 12 && "large"}`}>
                {childname
                  ? deviceLarge
                    ? childname.slice(0, 17)
                    : childname.slice(0, 16)
                  : "Fixer"}
              </div>
              <div className="price-s-print">
                Picture for illustrative purposes only, delivered box may look
                different.
              </div>
            </div>
            <div className="signup-block">
              <h2 className="block-header">Enter your details:</h2>
              <input
                type="text"
                name="NAME"
                id="mce-NAME"
                value={fName + " " + lName}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder="Your first name"
                name="FNAME"
                id="mce-FNAME"
                required
              />
              <input
                type="text"
                placeholder="Your last name"
                name="LNAME"
                id="mce-LNAME"
                required
              />
              <input
                type="email"
                name="EMAIL"
                placeholder="Your email address"
                className="required wide"
                id="mce-EMAIL"
                required
              />
              <input
                type="text"
                name="POSTCODE"
                placeholder="Your post code"
                id="mce-POSTCODE"
                onChange={(e) => {
                  setPostcode(e.target.value);
                }}
                required
              />
              <select
                name="ADDRESS[country]"
                id="mce-ADDRESS-country"
                className="select minimal half"
                required
              >
                {/* <option disabled value=""></option> */}
                <option value="262">United Kingdom</option>
                <option value="164">United States</option>
                <option value="286">Aaland Islands</option>
                <option value="274">Afghanistan</option>
                <option value="2">Albania</option>
                <option value="3">Algeria</option>
                <option value="178">American Samoa</option>
                <option value="4">Andorra</option>
                <option value="5">Angola</option>
                <option value="176">Anguilla</option>
                <option value="175">Antigua And Barbuda</option>
                <option value="6">Argentina</option>
                <option value="7">Armenia</option>
                <option value="179">Aruba</option>
                <option value="8">Australia</option>
                <option value="9">Austria</option>
                <option value="10">Azerbaijan</option>
                <option value="11">Bahamas</option>
                <option value="12">Bahrain</option>
                <option value="13">Bangladesh</option>
                <option value="14">Barbados</option>
                <option value="15">Belarus</option>
                <option value="16">Belgium</option>
                <option value="17">Belize</option>
                <option value="18">Benin</option>
                <option value="19">Bermuda</option>
                <option value="20">Bhutan</option>
                <option value="21">Bolivia</option>
                <option value="325">Bonaire, Saint Eustatius and Saba</option>
                <option value="22">Bosnia and Herzegovina</option>
                <option value="23">Botswana</option>
                <option value="181">Bouvet Island</option>
                <option value="24">Brazil</option>
                <option value="180">Brunei Darussalam</option>
                <option value="25">Bulgaria</option>
                <option value="26">Burkina Faso</option>
                <option value="27">Burundi</option>
                <option value="28">Cambodia</option>
                <option value="29">Cameroon</option>
                <option value="30">Canada</option>
                <option value="31">Cape Verde</option>
                <option value="32">Cayman Islands</option>
                <option value="33">Central African Republic</option>
                <option value="34">Chad</option>
                <option value="35">Chile</option>
                <option value="36">China</option>
                <option value="185">Christmas Island</option>
                <option value="37">Colombia</option>
                <option value="204">Comoros</option>
                <option value="38">Congo</option>
                <option value="183">Cook Islands</option>
                <option value="268">Costa Rica</option>
                <option value="275">Cote D'Ivoire</option>
                <option value="40">Croatia</option>
                <option value="276">Cuba</option>
                <option value="298">Curacao</option>
                <option value="41">Cyprus</option>
                <option value="42">Czech Republic</option>
                <option value="318">Democratic Republic of the Congo</option>
                <option value="43">Denmark</option>
                <option value="44">Djibouti</option>
                <option value="289">Dominica</option>
                <option value="187">Dominican Republic</option>
                <option value="45">Ecuador</option>
                <option value="46">Egypt</option>
                <option value="47">El Salvador</option>
                <option value="48">Equatorial Guinea</option>
                <option value="49">Eritrea</option>
                <option value="50">Estonia</option>
                <option value="51">Ethiopia</option>
                <option value="189">Falkland Islands</option>
                <option value="191">Faroe Islands</option>
                <option value="52">Fiji</option>
                <option value="53">Finland</option>
                <option value="54">France</option>
                <option value="193">French Guiana</option>
                <option value="277">French Polynesia</option>
                <option value="56">Gabon</option>
                <option value="57">Gambia</option>
                <option value="58">Georgia</option>
                <option value="59">Germany</option>
                <option value="60">Ghana</option>
                <option value="194">Gibraltar</option>
                <option value="61">Greece</option>
                <option value="195">Greenland</option>
                <option value="192">Grenada</option>
                <option value="196">Guadeloupe</option>
                <option value="62">Guam</option>
                <option value="198">Guatemala</option>
                <option value="270">Guernsey</option>
                <option value="63">Guinea</option>
                <option value="65">Guyana</option>
                <option value="200">Haiti</option>
                <option value="66">Honduras</option>
                <option value="67">Hong Kong</option>
                <option value="68">Hungary</option>
                <option value="69">Iceland</option>
                <option value="70">India</option>
                <option value="71">Indonesia</option>
                <option value="278">Iran</option>
                <option value="279">Iraq</option>
                <option value="74">Ireland</option>
                <option value="323">Isle of Man</option>
                <option value="75">Israel</option>
                <option value="76">Italy</option>
                <option value="202">Jamaica</option>
                <option value="78">Japan</option>
                <option value="288">Jersey (Channel Islands)</option>
                <option value="79">Jordan</option>
                <option value="80">Kazakhstan</option>
                <option value="81">Kenya</option>
                <option value="203">Kiribati</option>
                <option value="82">Kuwait</option>
                <option value="83">Kyrgyzstan</option>
                <option value="84">Lao People's Democratic Republic</option>
                <option value="85">Latvia</option>
                <option value="86">Lebanon</option>
                <option value="87">Lesotho</option>
                <option value="88">Liberia</option>
                <option value="281">Libya</option>
                <option value="90">Liechtenstein</option>
                <option value="91">Lithuania</option>
                <option value="92">Luxembourg</option>
                <option value="208">Macau</option>
                <option value="93">Macedonia</option>
                <option value="94">Madagascar</option>
                <option value="95">Malawi</option>
                <option value="96">Malaysia</option>
                <option value="97">Maldives</option>
                <option value="98">Mali</option>
                <option value="99">Malta</option>
                <option value="207">Marshall Islands</option>
                <option value="210">Martinique</option>
                <option value="100">Mauritania</option>
                <option value="212">Mauritius</option>
                <option value="241">Mayotte</option>
                <option value="101">Mexico</option>
                <option value="102">Moldova, Republic of</option>
                <option value="103">Monaco</option>
                <option value="104">Mongolia</option>
                <option value="290">Montenegro</option>
                <option value="294">Montserrat</option>
                <option value="105">Morocco</option>
                <option value="106">Mozambique</option>
                <option value="242">Myanmar</option>
                <option value="107">Namibia</option>
                <option value="108">Nepal</option>
                <option value="109">Netherlands</option>
                <option value="110">Netherlands Antilles</option>
                <option value="213">New Caledonia</option>
                <option value="111">New Zealand</option>
                <option value="112">Nicaragua</option>
                <option value="113">Niger</option>
                <option value="114">Nigeria</option>
                <option value="217">Niue</option>
                <option value="214">Norfolk Island</option>
                <option value="272">North Korea</option>
                <option value="116">Norway</option>
                <option value="117">Oman</option>
                <option value="118">Pakistan</option>
                <option value="222">Palau</option>
                <option value="282">Palestine</option>
                <option value="119">Panama</option>
                <option value="219">Papua New Guinea</option>
                <option value="120">Paraguay</option>
                <option value="121">Peru</option>
                <option value="122">Philippines</option>
                <option value="221">Pitcairn</option>
                <option value="123">Poland</option>
                <option value="124">Portugal</option>
                <option value="126">Qatar</option>
                <option value="315">Republic of Kosovo</option>
                <option value="127">Reunion</option>
                <option value="128">Romania</option>
                <option value="129">Russia</option>
                <option value="130">Rwanda</option>
                <option value="205">Saint Kitts and Nevis</option>
                <option value="206">Saint Lucia</option>
                <option value="324">Saint Martin</option>
                <option value="237">Saint Vincent and the Grenadines</option>
                <option value="132">Samoa (Independent)</option>
                <option value="227">San Marino</option>
                <option value="255">Sao Tome and Principe</option>
                <option value="133">Saudi Arabia</option>
                <option value="134">Senegal</option>
                <option value="326">Serbia</option>
                <option value="135">Seychelles</option>
                <option value="136">Sierra Leone</option>
                <option value="137">Singapore</option>
                <option value="302">Sint Maarten</option>
                <option value="138">Slovakia</option>
                <option value="139">Slovenia</option>
                <option value="223">Solomon Islands</option>
                <option value="140">Somalia</option>
                <option value="141">South Africa</option>
                <option value="257">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="142">South Korea</option>
                <option value="311">South Sudan</option>
                <option value="143">Spain</option>
                <option value="144">Sri Lanka</option>
                <option value="293">Sudan</option>
                <option value="146">Suriname</option>
                <option value="225">Svalbard and Jan Mayen Islands</option>
                <option value="147">Swaziland</option>
                <option value="148">Sweden</option>
                <option value="149">Switzerland</option>
                <option value="285">Syria</option>
                <option value="152">Taiwan</option>
                <option value="260">Tajikistan</option>
                <option value="153">Tanzania</option>
                <option value="154">Thailand</option>
                <option value="233">Timor-Leste</option>
                <option value="155">Togo</option>
                <option value="232">Tonga</option>
                <option value="234">Trinidad and Tobago</option>
                <option value="156">Tunisia</option>
                <option value="157">Turkey</option>
                <option value="158">Turkmenistan</option>
                <option value="287">Turks &amp; Caicos Islands</option>
                <option value="159">Uganda</option>
                <option value="161">Ukraine</option>
                <option value="162">United Arab Emirates</option>
                <option value="163">Uruguay</option>
                <option value="165">Uzbekistan</option>
                <option value="239">Vanuatu</option>
                <option value="166">Vatican City State (Holy See)</option>
                <option value="167">Venezuela</option>
                <option value="168">Vietnam</option>
                <option value="169">Virgin Islands (British)</option>
                <option value="238">Virgin Islands (U.S.)</option>
                <option value="188">Western Sahara</option>
                <option value="170">Yemen</option>
                <option value="173">Zambia</option>
                <option value="174">Zimbabwe</option>
              </select>

              <input
                type="text"
                value="-"
                name="ADDRESS[addr1]"
                id="mce-ADDRESS-addr1"
                style={{ display: "none" }}
              />

              <input
                type="text"
                value="-"
                name="ADDRESS[addr2]"
                id="mce-ADDRESS-addr2"
                style={{ display: "none" }}
              />

              <input
                type="text"
                value="-"
                name="ADDRESS[city]"
                id="mce-ADDRESS-city"
                style={{ display: "none" }}
              />

              <input
                type="text"
                value="-"
                name="ADDRESS[state]"
                id="mce-ADDRESS-state"
                style={{ display: "none" }}
              />

              <input
                type="text"
                name="ADDRESS[zip]"
                id="mce-ADDRESS-zip"
                value={postcode}
                style={{ display: "none" }}
              />
            </div>

            <div className="signup-block">
              <h2 className="block-header">How did you hear about us?</h2>
              <select
                name="CSOURCE"
                id="mce-CSOURCE"
                className="select minimal full placeholder"
                onChange={(event) => setSource(event.target.value)}
                title="How did you first hear about team repair?"
                style={
                  source === ""
                    ? { fontStyle: "italic", color: "rgba(43, 48, 58, 0.65)" }
                    : undefined
                }
              >
                <option disabled selected value="">
                  How did you hear about us?
                </option>
                <option value="linkedIn">LinkedIn</option>
                <option value="tikTok">TikTok</option>
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
                <option value="friend">A friend</option>
                <option value="family">A family member</option>
                <option value="school">Through my child's school</option>
                <option value="media">Media</option>
                <option value="google">Google</option>
                <option value="other">Other (please describe)</option>
              </select>
              {source === "other" && (
                <input
                  type="text"
                  name="CSOU_OTHER"
                  placeholder="Please type how you found us."
                  className="wide"
                  id="mce-CSOU_OTHER"
                />
              )}
            </div>
            <div
              className={`signup-block ${
                discountEntryOpen ? "" : "collapsed"
              } discount`}
              style={!discountEntryOpen ? { maxHeight: 52 } : {}}
            >
              <div
                className="block-header with-expand"
                onClick={() => setDiscountEntryOpen(!discountEntryOpen)}
              >
                <h2>Add a referral code</h2>
                <MdOutlineExpandMore
                  size={32}
                  className={`expand-arrow ${discountEntryOpen ? "close" : ""}`}
                />{" "}
              </div>
              {/* <div className="max-width-block">
                We'll keep this safe for when it's your turn to purchase!
              </div> */}
              <div
                className="discount-field-container"
                style={{ display: promoCodeId === null ? "flex" : "none" }}
              >
                <input
                  type="text"
                  name="REFERRAL"
                  placeholder="Referral code"
                  className="wide discount"
                  id="mce-REFERRAL"
                  onChange={(event) => {
                    setDiscountCode(event.target.value);
                    setDiscountErrormessage("");
                  }}
                  value={discountCode}
                />
                <button
                  className="discount-button"
                  onClick={(event) => {
                    event.preventDefault();
                    checkDiscountCode();
                  }}
                >
                  <div>Apply</div>
                </button>
              </div>

              <div
                className="discount-field-container added"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: promoCodeId === null ? "none" : "flex",
                }}
              >
                <h3>Referral code added: {discountCode}</h3>
                <IoMdCloseCircle
                  size={32}
                  onClick={() => {
                    setDiscountCode("");
                    setPromoCodeId(null);
                    setDiscountErrormessage("");
                    setDiscountAmountOff(null);
                    setDiscountPercentOff(null);
                  }}
                  className="close-icon"
                />
              </div>

              {discountErrorMessage ? (
                <div className="discount-error-message">
                  {discountErrorMessage}
                </div>
              ) : null}
            </div>
            <div className="signup-block">
              <h2 className="block-header">How would you like to pay?</h2>
              <div className="plan-button-cont">
                <button
                  className={
                    planSelected == 1 ? "plan-button-checked" : "plan-button"
                  }
                  type="button"
                  onClick={() => {
                    setPlanSelected(1);
                  }}
                >
                  
                  <div >
                    <div>Monthly</div>
                    <div style={{fontWeight:300, marginTop:2}}>£{displayedPrice[1]}/kit</div>
                  </div>
                </button>
                <input
                  type="radio"
                  checked={planSelected == 1}
                  value="1"
                  name="PLAN"
                  id="mce-PLAN-0"
                  style={{ display: "none" }}
                />
                <button
                  className={
                    planSelected == 12 ? "plan-button-checked" : "plan-button"
                  }
                  type="button"
                  onClick={() => {
                    setPlanSelected(12);
                  }}
                >
                  <div className="button-bubble">
                    {(discount * 100).toFixed(1)}% off
                    {/* 1 box free */}
                    {/* Save £{(displayedPrice[12] * 12 * discount).toFixed(2)} */}
                  </div>
                  <div >
                    <div>Full Programme (12 months)</div>
                    <div style={{fontWeight:300, marginTop:2}}>£{displayedPrice[12]}/kit</div>
                  </div>
                </button>
                <input
                  type="radio"
                  checked={planSelected == 12}
                  value="12"
                  name="PLAN"
                  id="mce-PLAN-2"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="price-block">
              <div className="price-row">
                <div>
                  <em>Cost per box:</em>
                </div>
                <div>£{displayedPrice[planSelected]}</div>
              </div>
              <div className="price-row">
                <div>
                  <em>Postage:</em>
                </div>
                <div>Free</div>
              </div>
              {discountAmountOff || discountPercentOff ? (
                <div className="price-row">
                  <div>
                    <em>Discount code ({discountCode}):</em>
                  </div>
                  {discountAmountOff ? (
                    <div>-£{discountAmountOff.toFixed(2)}</div>
                  ) : (
                    discountPercentOff && (
                      <div>
                        -£
                        {(
                          displayedPrice[planSelected.toString()] *
                          planSelected *
                          (discountPercentOff / 100)
                        ).toFixed(2)}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="price-row" style={{ fontWeight: "600" }}>
                <div>
                  <em>Total:</em>
                </div>
                <div>£{totalCost}</div>
              </div>
              <div className="price-s-print">
                Approximate pricing subject to change.
              </div>
              {planSelected == 1 ? (
                <div className="price-s-print">
                  Subscription would renew automatically. Cancel anytime.
                </div>
              ) : (
                <div className="price-s-print">
                  The full programme will be posted on a monthly basis.
                </div>
              )}
            </div>
            <input
              type="checkbox"
              id="gdpr_61464"
              name="gdpr[61464]"
              value="Y"
              hidden
              checked
              className="av-checkbox "
            />
            <input
              type="number"
              name="QPRICE"
              value={totalCost}
              style={{ display: "none" }}
              id="mce-QPRICE"
            />
            <button
              type="submit"
              className="submit-button"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            >
              <div>Join the waitlist</div>
              <div className="submit-arrow">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.333 6.66669L26.6663 16L17.333 25.3334M5.33301 16H26.6663H5.33301Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </button>

            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_48a950bb4c024915921a41b89_c2bd85b8fd"
              />
            </div>
            <div style={{ height: "24px" }}></div>
            <div className="signup-block">
              <div className="small-print">
                By clicking join the waitlist you agree to having your data
                handled in accordance to our{" "}
                <a
                  href="https://uploads-ssl.webflow.com/621cc721d36bc65c26571c8e/6318c501b513b5957de65b32_Privacy%20Policy%20Branded.pdf"
                  target="__blank"
                >
                  privacy policy
                </a>
                . After signing up we will keep you informed as we develop our
                product and let you know when we’re ready. Joining the waitlist
                is non-commital and you may unsubscribe at any time.
              </div>
            </div>
          </div>
        </form>
      ) : (
        <>
          <div className="navbar-spacing"></div>
          <h1 className="blue padded" style={{ maxWidth: 900 }}>
            You're on our waitlist! Thanks for signing up
          </h1>
          <div className="signup-block">
            <div className={deviceLarge ? "center" : ""}>
              We're so excited to have you on our list! Since we're just
              starting out we can't quite meet demand for our kits yet - but
              we've started sending out our first kits to waitlist customers,
              and should be with you in no time!
            </div>
            <div
              className={deviceLarge ? "center" : ""}
              style={{ display: "inline-block" }}
            >
              {" "}
              We'll let you know via email as soon as your kit is ready. If you
              have any questions or thoughts please reach out on our socials or
              with <a href="mailto:hello@team.repair">hello@team.repair</a>.
            </div>
          </div>
          <button
            className="submit-button"
            style={{ width: "248px" }}
            onClick={() => {
              window.location.href = "https://team.repair";
            }}
          >
            <div>Back to homepage</div>
            <div className="submit-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.333 6.66669L26.6663 16L17.333 25.3334M5.33301 16H26.6663H5.33301Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </button>
          <div
            style={deviceLarge ? { height: "48px" } : { height: "16px" }}
          ></div>
        </>
      )}
      <div className="workshop-block">
        <img
          src={deviceLarge ? "workshop-fade.webp" : "workshop.webp"}
          alt=""
          className="workshop-image"
        />
        <div className="workshop-text-block">
          <h1 className={deviceLarge ? "pink right" : "pink"}>
            Join us for our FREE workshops!
          </h1>
          <div>
            We regularly attend repair cafes to offer the Team Repair experience
            for free! Follow us on instagram to hear about our next event near
            you.
          </div>
          <button
            className="follow-button"
            onClick={() => {
              window.open("https://instagram.com/teamrepair");
            }}
          >
            <div>Follow us on Instagram</div>
            <div className="submit-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.333 6.66669L26.6663 16L17.333 25.3334M5.33301 16H26.6663H5.33301Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default WaitlistSignup;
