import React from "react";
import logo from "./logo.svg";

function Footer() {
  return (
      <div className="footer-cont">
        <div className="footer-text">
          © Team Repair Limited, 2022. Company No. 13815656
        </div>
      </div>
  );
}

export default Footer;
