import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GearLoader from "../elements/gearLoader";
import SubmitButton from "../elements/submitButton";
import getMobileOperatingSystem from "../utils/detectPlatform";
import logo from "./logo.svg";

type PostPurchaseSignupProps = {
  deviceLarge: boolean;
  dev?: boolean;
  env?: "local" | "stag" | "prod";
};

function PostPurchaseSignup({
  deviceLarge,
  dev,
  env,
}: PostPurchaseSignupProps) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [childname, setChildname] = useState("");
  const [childage, setChildage] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [addressPostcode, setAddressPostcode] = useState("");

  const [actionLoading, setActionLoading] = useState(false);
  const [actionComplete, setActionComplete] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrormessage] = useState("");
  const [display, setDisplay] = useState<
    | "loading"
    | "password"
    | "fullDetails"
    | "complete"
    | "error"
    | "resendEmail"
  >("loading");

  const SERVER_URL =
    env === "stag"
      ? "https://dev-api.teamrepair.dev"
      : env === "prod"
      ? "https://api.teamrepair.dev"
      : "http://localhost:8000";

  const { token } = useParams();

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    window.addEventListener("input", () => {
      setErrormessage("");
    });
  }, []);

  function verifyToken() {
    if (token) {
      console.log("token: " + token);
      setErrormessage("");

      fetch(SERVER_URL + "/web/verifysignuptoken", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          signup_token: token,
        }),
      })
        .then((res) => {
          return res.json().then((data) => {
            if (data["success"] || data["error"]) {
              data.status = res.status;
              return data;
            } else {
              throw res;
            }
          });
        })
        .then((data) => {
          if (data["success"]) {
            setEmail(data.email);
            if (data.firstname) {
              setFirstname(firstname);
              setDisplay("password");
            } else {
              // Pre user can't be found - request the full details
              setDisplay("fullDetails");
            }
          } else if (data["error"])
            if (data["error"]["trace_id"] === "web_B3GP") {
              // No preuser found - fatal error
              setErrormessage(
                data.error.message + " (code: " + data.error.trace_id + ")"
              );
              setDisplay("error");
            } else if (data["error"]["trace_id"] === "web_DPP6") {
              // User already found in database - i.e. link been used before, or verfiy email been sent in error.
              setDisplay("complete");
            } else if (data["error"]["trace_id"] === "web_7BSB") {
              // Token has expired, show resend button
              setDisplay("resendEmail");
            } else if (data["error"]["trace_id"] === "web_6777") {
              // Bad Token
              setErrormessage(
                data.error.message + " (code: " + data.error.trace_id + ")"
              );
              setDisplay("error");
            } else if (data["error"]["trace_id"] === "web_XF87") {
              // Bad Token
              setErrormessage(
                data.error.message + " (code: " + data.error.trace_id + ")"
              );
              setDisplay("error");
            }
        })
        .catch((err) => {
          console.log("Error: " + err.status);
          setErrormessage((err.status, " ,Unknown error"));
          setDisplay("error");
        });
    }
  }

  function resendToken() {
    if (token) {
      console.log("token: " + token);

      fetch(SERVER_URL + "/web/resendsignuptoken", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          signup_token: token,
        }),
      })
        .then((res) => {
          return res.json().then((data) => {
            if (data["success"] || data["error"]) {
              data.status = res.status;
              return data;
            } else {
              throw res;
            }
          });
        })
        .then((data) => {
          if (data["success"]) {
            setSuccessMessage("The email is on its way!");
          } else if (data["error"]) {
            setErrormessage(
              data.error.message + " (code: " + data.error.trace_id + ")"
            );
          } else {
            throw new Error();
          }
        })
        .catch((err) => {
          console.log("Error: " + err.status);
          setErrormessage((err.status, " ,Unknown error"));
        });
    }
  }

  function submitDetails() {
    setActionLoading(true);
    // All fields must have data
    if (
      firstname === "" ||
      lastname === "" ||
      phone === "" ||
      childname === "" ||
      childage === "" ||
      password1 === "" ||
      password2 === ""
    ) {
      setErrormessage("Please fill in every field to continue!");
      setActionLoading(false);
      return false;
    }

    // Password Validation
    const passLength = password1.length > 7 ? true : false;
    const passSpecialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passSymbols = passSpecialChars.test(password1);
    const passNumber = /\d+/.test(password1);
    const passCapital = /[A-Z]/.test(password1);

    if (!passLength || !passCapital || !passSymbols || !passNumber) {
      setErrormessage(
        "Passwords must be at least 9 characters, and include numbers, capitals, and symbols."
      );
      setActionLoading(false);
      return false;
    } else if (password1 !== password2) {
      console.log("No password");
      setErrormessage("Password 1 and Password 2 must be the same.");
      setActionLoading(false);
      return false;
    }

    // Phone number validation
    const length = phone[0] === "+" ? 13 : 11;
    const phoneNumbers = /^\+?[0-9]{1,14}$/.test(phone);
    console.log(phone);
    const phoneLength = phone.length === length ? true : false;
    console.log(phoneLength);
    if (!phoneNumbers || !phoneLength) {
      setErrormessage("Please use a valid phone number");
      setActionLoading(false);
      return false;
    }

    // Email validation
    const emailFormat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    if (!emailFormat) {
      setErrormessage("Not a valid email address.");
      setActionLoading(false);
      return false;
    }

    fetch(SERVER_URL + "/web/finishaccountcreation", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        childname: childname,
        childage: childage,
        password: password1,
        signup_token: token,
        type: 1,
        shipping_address: {
          line1: addressLine1,
          line2: addressLine2,
          city: addressTown,
          country: "United Kingdom",
        },
        postcode: addressPostcode,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setDisplay("complete");
          setActionLoading(false);
          setActionComplete(true);
        } else if (data["error"]) {
          setErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
          setActionLoading(false);
        } else {
          setActionLoading(false);
          throw new Error();
        }
      })
      .catch((err) => {
        setActionLoading(false);
        console.log("Error: " + err.status);
        setErrormessage((err.status, " ,Unknown error"));
      });
  }

  function submitPassword() {
    // All fields must have data
    if (password1 === "" || password2 === "") {
      setErrormessage("Please fill in both fields to continue!");
      setActionLoading(false);
      return false;
    }

    // Password Validation
    const passLength = password1.length > 7 ? true : false;
    const passSpecialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passSymbols = passSpecialChars.test(password1);
    const passNumber = /\d+/.test(password1);
    const passCapital = /[A-Z]/.test(password1);

    if (!passLength || !passCapital || !passSymbols || !passNumber) {
      setErrormessage(
        "Passwords must be at least 9 characters, and include numbers, capitals, and symbols."
      );
      setActionLoading(false);
      return false;
    } else if (password1 !== password2) {
      setErrormessage("Password 1 and Password 2 must be identical.");
      setActionLoading(false);
      return false;
    }

    fetch(SERVER_URL + "/web/finishaccountcreation", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password1,
        signup_token: token,
        type: 0,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setActionLoading(false);
          setActionComplete(true);
          setDisplay("complete");
        } else if (data["error"]) {
          setActionLoading(false);
          setErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          setActionLoading(false);
          throw new Error();
        }
      })
      .catch((err) => {
        setActionLoading(false);
        setErrormessage(err.status);
        console.log("error: ", err.status, err);
      });
  }

  if (display === "fullDetails") {
    return (
      <form
        className="signup-container"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="navbar-spacing"></div>
        <h1 className="blue padded">Complete account setup</h1>
        <div className="signup-block">
          <h2 className="block-header">Enter your details:</h2>
          <input
            value={email}
            id="email"
            type="email"
            readOnly
            name="email"
            autoComplete="username"
            style={{ display: "none" }}
          />
          <input
            type="text"
            placeholder="First name"
            name="firstname"
            id="firstname"
            onChange={(event) => setFirstname(event.target.value)}
            value={firstname}
            required
          />
          <input
            type="text"
            name="lastname"
            placeholder="Last name"
            id="lastname"
            onChange={(event) => setLastname(event.target.value)}
            value={lastname}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone number"
            className="required wide"
            id="phone"
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            required
          />
        </div>
        <div className="signup-block">
          <h2 className="block-header">Enter your child's details:</h2>
          <input
            type="text"
            name="childname"
            placeholder="Child's nickname"
            autoComplete="off"
            onChange={(event) => setChildname(event.target.value)}
            value={childname}
          />
          <div className="age-cont">
            <label>Child's age: </label>
            <select
              name="childage"
              id="mce-AGE"
              className="select minimal"
              onChange={(event) => setChildage(event.target.value)}
              value={childage}
            >
              <option value="">Select</option>
              <option value="&lt;5">&lt;5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16+">16+</option>
            </select>
          </div>
        </div>
        <div className="signup-block">
          <h2 className="block-header">Set your account password: </h2>
          <input
            type="password"
            name="password1"
            placeholder="Password"
            className="required wide"
            id="new-password1"
            autoComplete="new-password"
            onChange={(event) => setPassword1(event.target.value)}
            value={password1}
            required
          />
          <input
            type="password"
            name="password2"
            placeholder="Confirm password"
            className="required wide"
            id="new-password2"
            autoComplete="new-password"
            onChange={(event) => setPassword2(event.target.value)}
            value={password2}
            required
          />
        </div>
        <div className="signup-block" style={{ overflow: "visible" }}>
          <h2 className="block-header">
            {/* {returnCMS(pageCMS, 3, "Enter your details:")} */}
            Enter your shipping address:
          </h2>
          {/* <div className="block-width"> */}
          <input
            type="text"
            name="line_1"
            id="line_1"
            autoComplete="address-line1"
            placeholder="Address first line"
            className="required wide"
            onChange={(event) => setAddressLine1(event.target.value)}
            value={addressLine1}
            required
          />
          {/* </div> */}
          <input
            type="text"
            name="line_2"
            id="line_2"
            autoComplete="address-line2"
            placeholder="Address second line"
            className="wide"
            onChange={(event) => setAddressLine2(event.target.value)}
            value={addressLine2}
          />
          <input
            type="text"
            name="post_town"
            id="post_town"
            autoComplete="address-level1"
            placeholder="City"
            className="required"
            onChange={(event) => setAddressTown(event.target.value)}
            value={addressTown}
            required
          />
          <input
            type="text"
            name="postcode"
            id="postcode"
            autoComplete="postal-code"
            placeholder="Postcode"
            className="required"
            onChange={(event) => setAddressPostcode(event.target.value)}
            value={addressPostcode}
            required
          />
        </div>

        <div className="signup-block" style={{ paddingBottom: 0 }}>
          {errorMessage ? (
            <div className="error-message">Error message: {errorMessage}</div>
          ) : null}
        </div>

        <SubmitButton
          complete={actionComplete}
          loading={actionLoading}
          text={"Create Account"}
          width={220}
          onClick={() => {
            setActionLoading(true);
            submitDetails();
          }}
        />

        <div style={{ height: "24px" }}></div>
        <div className="signup-block">
          <div className="small-print">
            By clicking join the waitlist you agree to having your data handled
            in accordance to our{" "}
            <a
              href="https://uploads-ssl.webflow.com/621cc721d36bc65c26571c8e/6318c501b513b5957de65b32_Privacy%20Policy%20Branded.pdf"
              target="__blank"
            >
              privacy policy
            </a>
            . After signing up we will keep you informed as we develop our
            product and let you know when we’re ready. Joining the waitlist is
            non-commital and you may unsubscribe at any time.
          </div>
        </div>
      </form>
    );
  } else if (display === "password") {
    return (
      <form
        className="signup-container"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="navbar-spacing"></div>
        <h1 className="blue padded">Complete account setup</h1>

        <div className="signup-block">
          <h2 className="block-header">Set your account password: </h2>
          <input
            value={email}
            id="email"
            type="email"
            readOnly
            name="email"
            autoComplete="username"
            style={{ display: "none" }}
          />
          <input
            type="password"
            name="password1"
            placeholder="Password"
            className="required wide"
            id="new-password1"
            autoComplete="new-password"
            onChange={(event) => setPassword1(event.target.value)}
            value={password1}
            required
          />
          <input
            type="password"
            name="password2"
            placeholder="Confirm password"
            className="required wide"
            id="new-password2"
            autoComplete="new-password"
            onChange={(event) => setPassword2(event.target.value)}
            value={password2}
            required
          />
        </div>

        <div className="signup-block">
          {errorMessage ? (
            <div className="error-message">{errorMessage}</div>
          ) : null}
        </div>

        <SubmitButton
          complete={actionComplete}
          loading={actionLoading}
          text={"Save password"}
          width={220}
          onClick={() => {
            setActionLoading(true);
            submitPassword();
          }}
        />
      </form>
    );
  } else if (display === "complete") {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>

        <h1 className="blue padded">You're ready to go!</h1>
        <div className={(deviceLarge ? "center" : "") + " padded"}>
          Your account has been successfully created, and you're now ready to
          sign into our app.
        </div>

        <div className="app-icon-cont" style={{ marginTop: 48 }}>
          <img src={"/app-icon.png"} alt="Team Repair" className="app-icon" />
        </div>
        <div className="appstore-title-cont padded">
          <h2 className="center">Team Repair</h2>
          <div>Box Guides and Digital Content</div>
        </div>
        <div className="appstore-logos-container">
          {getMobileOperatingSystem() === "iOS" ||
          getMobileOperatingSystem() === "unknown" ? (
            <a
              href="https://apps.apple.com/gb/app/team-repair/id1660930642?itsct=apps_box_badge&amp;itscg=30200"
              className="appstore-cont"
            >
              <img
                src={"/apple-appstore.png"}
                alt="Download on the App Store"
                className="appstore-cont"
              />
            </a>
          ) : null}
          {getMobileOperatingSystem() === "Android" ||
          getMobileOperatingSystem() === "unknown" ? (
            <a
              className="appstore-cont"
              href="https://play.google.com/store/apps/details?id=repair.team.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                src={"/android-appstore.png"}
                alt="Get it on Google Play"
                className="appstore-cont"
              />
            </a>
          ) : null}
          {getMobileOperatingSystem() === "Amazon" ||
          getMobileOperatingSystem() === "unknown" ? (
            <a
              className="appstore-cont"
              href="https://www.amazon.co.uk/Team-Repair-Limited/dp/B0BW9RV78P/ref=sr_1_1?keywords=team+repair&qid=1677699413&s=mobile-apps&sr=1-1"
            >
              <img
                src={"/amazon-appstore.png"}
                alt="Available at Amazon"
                className="appstore-cont"
              />
            </a>
          ) : null}
        </div>
      </div>
    );
  } else if (display === "loading") {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>
        <div className="signup-block">
          <h2
            className="blue padded"
            style={{ textAlign: "center", marginBottom: 24 }}
          >
            Just grabbing a few details...
          </h2>
          <div>
            <GearLoader large color="#5a8eff" />
          </div>
        </div>
      </div>
    );
  } else if (display === "resendEmail") {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>
        <div className="signup-block">
          <h1
            className="blue padded"
            style={{ textAlign: "center", marginBottom: 24 }}
          >
            Sorry, that links expired!
          </h1>
          <div className={deviceLarge ? "center" : ""}>
            Unfortunately that link doesn't work anymore! Click the button below
            to send a new one, and I'll race you back to your inbox...{" "}
          </div>
        </div>
        <SubmitButton
          complete={actionComplete}
          text={"Resend email"}
          width={220}
          onClick={() => {
            setActionComplete(true);
            resendToken();
          }}
        />
        <div className="signup-block" style={{ paddingTop: 16 }}>
          {errorMessage ? (
            <div className="error-message">{errorMessage}</div>
          ) : null}
          {successMessage ? (
            <div className="success-message">{successMessage}</div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>
        <div className="signup-inner-container">
          <h2
            className="blue padded"
            style={{ textAlign: "center", marginBottom: 24 }}
          >
            Oh no! Somethings gone wrong!
          </h2>
          <div className={deviceLarge ? "center" : ""}>
            The url provided is not correct. Please double check you've copied
            it correctly from your email. If the problem persists please contact
            us quoting the error message and we'll get it fixed as soon as we
            can!{" "}
          </div>
          {errorMessage ? (
            <div className="error-message">Error message: {errorMessage}</div>
          ) : null}
          <a
            className="submit-button"
            href={`mailto:admin@team.repair?subject=Account Setup Error - ${errorMessage}`}
          >
            <div>Contact us</div>
            <div className="submit-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.333 6.66669L26.6663 16L17.333 25.3334M5.33301 16H26.6663H5.33301Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default PostPurchaseSignup;
