import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { BiInfoCircle } from "react-icons/bi";
import WorkshopBlock from "../elements/workshopBlock";
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { getPageCMS, returnCMS } from "../utils/cmsUtils";
import SubmitButton from "../elements/submitButton";
import { AddressFinder } from "@ideal-postcodes/address-finder";

type PrepurchaseSignupProps = {
  deviceLarge: boolean;
  dev?: boolean;
  env?: "local" | "stag" | "prod";
  pageVersion: 1 | 2 | 3 | 4;
};

function PrepurchaseSignup({ deviceLarge, dev, env }: PrepurchaseSignupProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [addressPostcode, setAddressPostcode] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [promoCodeId, setPromoCodeId] = useState<null | string>(null);
  const [childname, setChildname] = useState("");
  const [childage, setChildage] = useState("");
  const [acceptZWaste, setAcceptZWaste] = useState(false);
  const [discountPercentOff, setDiscountPercentOff] = useState<null | number>(
    null
  );
  const [discountAmountOff, setDiscountAmountOff] = useState<null | number>(
    null
  );
  const [totalCost, setTotalCost] = useState("0");
  const [discountEntryOpen, setDiscountEntryOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  // const deposit = 10.0;

  const [planSelected, setPlanSelected] = useState(1);
  const [displayedPrice, setDisplayedPrice] = useState<{
    [key: string]: number;
  }>({ "1": 0.0, "2": 0.0 });

  const [errorMessage, setErrormessage] = useState("");
  const [discountErrorMessage, setDiscountErrormessage] = useState("");
  const [pageCMS, setPageCMS] = useState<{ [key: string]: any } | null>(null);

  const [checkoutOpen, setCheckoutOpen] = useState(true);

  const SERVER_URL =
    env === "stag"
      ? "https://dev-api.teamrepair.dev"
      : env === "prod"
      ? "https://api.teamrepair.dev"
      : "http://localhost:8000";

  useEffect(() => {
    window.addEventListener("input", () => {
      setErrormessage("");
    });
    reloadTotalCost();
    loadDiscountFromURL();
    getPageCMS("pre-purchase-1", "https://api.teamrepair.dev").then((cms) => {
      setPageCMS(cms);
      if (returnCMS(cms, 33, "27.99") !== "checkout-open" && env === "prod") {
        setCheckoutOpen(false);
      }
      console.log(cms);
      setDisplayedPrice({
        "1": parseFloat(returnCMS(cms, 31, "27.99")),
        "2": parseFloat(returnCMS(cms, 32, "23.99")),
      });
    });
    AddressFinder.setup({
      apiKey: process.env.REACT_APP_ADDRESS_API_KEY as string,
      containerStyle: {
        width: "100%",
      },
      defaultCountry: "GBR",
      detectCountry: true,
      restrictCountries: ["GBR"],
      outputFields: {
        line_1: "#line_1",
        line_2: "#line_2",
        post_town: "#post_town",
        postcode: "#postcode",
      },
    });
  }, []);

  useEffect(() => {
    reloadTotalCost();
  }, [planSelected, discountAmountOff, discountPercentOff, displayedPrice]);

  function loadDiscountFromURL() {
    const code = searchParams.get("code");
    if (code) {
      setDiscountEntryOpen(true);
      setDiscountCode(code);
      console.log(code);
      checkDiscountCode(code);
    }
  }

  function reloadTotalCost() {
    if (discountAmountOff) {
      setTotalCost(
        (
          displayedPrice[planSelected.toString()] * planSelected -
          discountAmountOff
        ).toFixed(2)
      );
    } else if (discountPercentOff) {
      setTotalCost(
        (
          displayedPrice[planSelected.toString()] *
          planSelected *
          ((100 - discountPercentOff) / 100)
        ).toFixed(2)
      );
    } else {
      setTotalCost(
        (displayedPrice[planSelected.toString()] * planSelected).toFixed(2)
      );
    }
  }

  function submitForm() {
    // All fields must have data
    setIsLoading(true);
    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      phone === "" ||
      childname === "" ||
      childage === ""
    ) {
      setErrormessage("Please fill in every field to continue!");
      setIsLoading(false);
      return false;
    }

    if (!acceptZWaste) {
      setErrormessage(
        "Please accept the zero waste commitment terms and conditions to continue."
      );
      setIsLoading(false);
      return false;
    }

    // Phone number validation
    const length = phone[0] === "+" ? 13 : 11;
    const phoneNumbers = /^\+?[0-9]{1,14}$/.test(phone);
    console.log(phone);
    const phoneLength = phone.length === length ? true : false;
    console.log(phoneLength);
    if (!phoneNumbers || !phoneLength) {
      setErrormessage("Please use a valid phone number");
      setIsLoading(false);
      return false;
    }

    // Email validation
    const emailFormat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    if (!emailFormat) {
      setErrormessage("Please enter a valid email address.");
      setIsLoading(false);
      return false;
    }

    let redirect_url: URL;

    fetch(SERVER_URL + "/web/createpreaccount", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        childname: childname,
        childage: childage,
        promo_code_id: promoCodeId,
        num_kits: planSelected,
        source_url: window.location.href,
        discount_code: discountCode,
        shipping_address: {
          line1: addressLine1,
          line2: addressLine2,
          city: addressTown,
          country: "United Kingdom",
        },
        postcode: addressPostcode,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setIsLoading(false);
          setIsComplete(true);
          // if (planSelected === 1) {
          //   // redirect_url = new URL("https://buy.stripe.com/9AQeXcgu91Gygtq7ss");
          //   redirect_url = new URL("https://buy.stripe.com/test_fZe16z97E0kucgw6op");
          // } else if (planSelected === 3) {
          //   // redirect_url = new URL ("https://buy.stripe.com/5kAbL0b9P70Sa52001");
          //   redirect_url = new URL(
          //     "https://buy.stripe.com/test_fZe2aDabIebkcgw4gg"
          //   );
          // }
          // redirect_url.searchParams.set("prefilled_email", email);
          // redirect_url.searchParams.set(
          //   "client_reference_id",
          //   data["order_id"]
          // );
          localStorage.setItem("customer_email", email);
          window.location.href = data.redirect_url;
        } else if (data["error"]) {
          setIsLoading(false);
          setErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          setIsLoading(false);
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        err.status
          ? setErrormessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setErrormessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  function checkDiscountCode(code?: string) {
    let codeToCheck;
    if (code) {
      codeToCheck = code;
    } else {
      codeToCheck = discountCode;
    }

    if (codeToCheck === "") {
      setPromoCodeId(null);
      return false;
    }

    fetch(SERVER_URL + "/web/verifycouponcode", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: codeToCheck,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setPromoCodeId(data["promo_code_id"]);
          if (data["percent_off"] != null) {
            setDiscountPercentOff(data["percent_off"]);
          } else if (data["amount_off"] != null) {
            setDiscountAmountOff(data["amount_off"] / 100);
          } else {
            setPromoCodeId(null);
            setDiscountErrormessage("Something went wrong, please try again.");
          }
          return reloadTotalCost();
        } else if (data["error"]) {
          setDiscountErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        err.status
          ? setDiscountErrormessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setDiscountErrormessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  if (checkoutOpen) {
    return (
      <form
        className="signup-container"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {/* <div> */}
        <div className="navbar-spacing"></div>
        <h1 className="blue padded">
          {returnCMS(pageCMS, 0, "Ready to repair?")}{" "}
          {env === "stag" || env === "local" ? "(" + env + ")" : null}
        </h1>
        <div className="signup-block">
          <h2 className="block-header">
            {returnCMS(pageCMS, 1, "Enter your child's details:")}
          </h2>
          <input
            type="text"
            name="childname"
            placeholder="Child's nickname"
            onChange={(event) => setChildname(event.target.value)}
            value={childname}
            required
            title="Child's nickname is used for personalisation of the app and kit only."
          />
          <div className="age-cont">
            <label>Child's age: </label>
            <select
              name="childage"
              id="mce-AGE"
              className="select minimal"
              onChange={(event) => setChildage(event.target.value)}
              value={childage}
              required
              title="Child's age is used to tailor content towards their ability"
            >
              <option disabled value="">
                Select
              </option>
              <option value="9">{"<"}10</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16+</option>
            </select>
          </div>
          {childage === "9" && (
            <div className="max-width-block" style={{ color: "#5a8eff" }}>
              {returnCMS(
                pageCMS,
                42,
                "Team Repair kits are designed for ages 10+"
              )}
            </div>
          )}
          <div className="max-width-block">{returnCMS(pageCMS, 38, "")}</div>
        </div>

        <div className="box-block">
          <img src="hero.jpg" alt="" className="box-image" />
          <div className={`box-name ${childname.length > 12 && "large"}`}>
            {childname
              ? deviceLarge
                ? childname.slice(0, 17)
                : childname.slice(0, 16)
              : "Fixer"}
          </div>
          <div className="price-s-print">
            {returnCMS(
              pageCMS,
              2,
              "Picture for illustrative purposes only, delivered box may look different."
            )}
          </div>
        </div>
        <div className="signup-block">
          <h2 className="block-header">
            {returnCMS(pageCMS, 3, "Enter your details:")}
          </h2>
          <input
            type="email"
            name="email"
            placeholder="Email address"
            className="required wide"
            id="email"
            onChange={(event) => setEmail(event.target.value.toLowerCase())}
            value={email}
            required
          />
          <input
            type="text"
            placeholder="First name"
            name="firstname"
            id="firstname"
            onChange={(event) => setFirstname(event.target.value)}
            value={firstname}
            required
          />
          <input
            type="text"
            name="lastname"
            placeholder="Last name"
            id="lastname"
            onChange={(event) => setLastname(event.target.value)}
            value={lastname}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone number"
            className="required wide"
            id="phone"
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            required
          />
        </div>
        <div className="signup-block" style={{ overflow: "visible" }}>
          <h2 className="block-header">
            {/* {returnCMS(pageCMS, 3, "Enter your details:")} */}
            Enter your shipping address:
          </h2>
          {/* <div className="block-width"> */}
          <input
            type="text"
            name="line_1"
            id="line_1"
            autoComplete="address-line1"
            placeholder="Address first line"
            className="required wide"
            onChange={(event) => setAddressLine1(event.target.value)}
            value={addressLine1}
            required
          />
          {/* </div> */}
          <input
            type="text"
            name="line_2"
            id="line_2"
            autoComplete="address-line2"
            placeholder="Address second line"
            className="wide"
            onChange={(event) => setAddressLine2(event.target.value)}
            value={addressLine2}
          />
          <input
            type="text"
            name="post_town"
            id="post_town"
            autoComplete="address-level1"
            placeholder="City"
            className="required"
            onChange={(event) => setAddressTown(event.target.value)}
            value={addressTown}
            required
          />
          <input
            type="text"
            name="postcode"
            id="postcode"
            autoComplete="postal-code"
            placeholder="Postcode"
            className="required"
            onChange={(event) => setAddressPostcode(event.target.value)}
            value={addressPostcode}
            required
          />
        </div>

        <div className="signup-block">
          <h2 className="block-header">
            {returnCMS(pageCMS, 4, "Zero-waste commitment")}
          </h2>
          <div className="max-width-block">
            {returnCMS(
              pageCMS,
              5,
              "At Team Repair we're doing everything we can to reduce our footprint on the environment. By returning your kit after a month, not only do you reduce the clutter of forgotten toys, but you help us complete our circular subscription. We re-break each gadget and send it to the next fixer creating almost no new e-waste.:"
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              boxSizing: "border-box",
            }}
            className="max-width-block"
          >
            <input
              type="checkbox"
              name="zero-waste"
              checked={acceptZWaste}
              onChange={(event) => {
                setAcceptZWaste(event.target.checked);
              }}
            />
            <div
              onClick={() => {
                setAcceptZWaste((value) => !value);
              }}
              style={{ width: "fit-content", cursor: "pointer" }}
            >
              {returnCMS(pageCMS, 6, "I understand my Team Repair kit")}{" "}
              <b>{returnCMS(pageCMS, 7, "must be returned within a month")}</b>
              {returnCMS(
                pageCMS,
                8,
                ", and agree to the zero-waste commitment"
              )}{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={returnCMS(pageCMS, 9, "https://google.com")}
              >
                {returnCMS(pageCMS, 10, "terms and conditions")}
              </a>
              .
            </div>
          </div>
        </div>
        <div
          className={`signup-block ${
            discountEntryOpen ? "" : "collapsed"
          } discount`}
          style={{ maxHeight: !discountEntryOpen ? 52 : undefined }}
        >
          <div
            className="block-header with-expand"
            onClick={() => setDiscountEntryOpen(!discountEntryOpen)}
          >
            <h2>{returnCMS(pageCMS, 11, "Add a discount code")}</h2>
            <MdOutlineExpandMore
              size={32}
              className={`expand-arrow ${discountEntryOpen ? "close" : ""}`}
            />{" "}
          </div>
          {promoCodeId === null ? (
            <div className="discount-field-container">
              <input
                type="text"
                name="discount"
                placeholder="Discount code"
                className="wide discount"
                id="discount"
                onChange={(event) => {
                  setDiscountCode(event.target.value);
                  setDiscountErrormessage("");
                }}
                value={discountCode}
              />
              <button
                className="discount-button"
                onClick={(event) => {
                  event.preventDefault();
                  checkDiscountCode();
                }}
              >
                <div>{returnCMS(pageCMS, 12, "Apply")}</div>
              </button>
            </div>
          ) : (
            <div
              className="discount-field-container added"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>
                {returnCMS(pageCMS, 13, "Promo code added:")} {discountCode}
              </h3>
              <IoMdCloseCircle
                size={32}
                onClick={() => {
                  setDiscountCode("");
                  setPromoCodeId(null);
                  setDiscountErrormessage("");
                  setDiscountAmountOff(null);
                  setDiscountPercentOff(null);
                }}
                className="close-icon"
              />
            </div>
          )}
          {discountErrorMessage ? (
            <div className="discount-error-message">{discountErrorMessage}</div>
          ) : null}
        </div>
        <div className="signup-block">
          <h2 className="block-header">
            {returnCMS(pageCMS, 14, "How many kits would you like?")}
          </h2>
          <div className="max-width-block">
            {returnCMS(pageCMS, 37, "We're still growing!")}
          </div>
          <div className="plan-button-cont">
            <button
              className={
                planSelected === 1 ? "plan-button-checked" : "plan-button"
              }
              type="button"
              onClick={() => {
                setPlanSelected(1);
              }}
            >
              {" "}
              <div>
                <div>{returnCMS(pageCMS, 15, "1 Kit")}</div>
                <div style={{ fontWeight: 300, marginTop: 2 }}>
                  £{displayedPrice[1]}/kit
                </div>
              </div>
            </button>
            <input
              type="radio"
              checked={planSelected === 1}
              value="1"
              readOnly
              name="PLAN"
              id="mce-PLAN-0"
              style={{ display: "none" }}
            />
            <button
              className={
                planSelected === 2 ? "plan-button-checked" : "plan-button"
              }
              // disabled
              type="button"
              onClick={() => {
                setPlanSelected(2);
              }}
            >
              <div>
                <div>{returnCMS(pageCMS, 16, "2 Kits")}</div>
                <div style={{ fontWeight: 300, marginTop: 2 }}>
                  £{displayedPrice[2]}/kit
                </div>
              </div>
              <div className="button-bubble">
                {returnCMS(pageCMS, 17, "14% off")}
              </div>
            </button>
            <input
              type="radio"
              checked={planSelected === 2}
              value="2"
              readOnly
              name="PLAN"
              id="mce-PLAN-2"
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="price-block">
          <div className="price-row">
            <div>
              <em>{returnCMS(pageCMS, 18, "Cost per box:")}</em>
            </div>
            <div>£{displayedPrice[planSelected.toString()].toFixed(2)}</div>
          </div>
          <div className="price-row">
            <div>
              <em>{returnCMS(pageCMS, 19, "Postage & Return:")}</em>
            </div>
            <div>{returnCMS(pageCMS, 20, "Free")}</div>
          </div>
          {discountAmountOff || discountPercentOff ? (
            <div className="price-row">
              <div>
                <em>
                  {returnCMS(pageCMS, 21, "Discount code (")}
                  {discountCode}
                  {returnCMS(pageCMS, 22, "):")}
                </em>
              </div>
              {discountAmountOff ? (
                <div>-£{discountAmountOff.toFixed(2)}</div>
              ) : (
                discountPercentOff && (
                  <div>
                    -£
                    {(
                      displayedPrice[planSelected.toString()] *
                      planSelected *
                      (discountPercentOff / 100)
                    ).toFixed(2)}
                  </div>
                )
              )}
            </div>
          ) : (
            <></>
          )}
          {/* <div className="price-row">
            <div className="tooltip">
              <em>Kit deposit:</em>
              <BiInfoCircle className="info-icon" size={20} />

              <div className="tooltiptext">
                The kit deposit will be returned in full after you send back the
                kit(s). Thank you for helping us achieve a zero waste
                subscription.
              </div>
            </div>
            <div>£{deposit.toFixed(2)}</div>
          </div> */}
          <div className="price-row" style={{ fontWeight: "600" }}>
            <div>
              <em>{returnCMS(pageCMS, 23, "Total:")}</em>
            </div>
            <div>£{totalCost}</div>
          </div>
          {planSelected == 1 ? (
            <div className="price-s-print">
              {returnCMS(
                pageCMS,
                24,
                "The kit must be sent back within a month and before further kits can be purchased."
              )}
            </div>
          ) : (
            <div className="price-s-print">
              {returnCMS(
                pageCMS,
                25,
                "The kits will be posted one by one, approximately monthly. We must have received the previous kit before the next kit is dispatched."
              )}
            </div>
          )}
        </div>

        <div className="signup-block">
          {errorMessage ? (
            <div className="error-message">{errorMessage}</div>
          ) : null}
        </div>
        <SubmitButton
          onClick={submitForm}
          text={returnCMS(pageCMS, 26, "Proceed to payment")}
          loading={isLoading}
          complete={isComplete}
          width={264}
        />

        <div style={{ height: "24px" }}></div>
        <div className="signup-block">
          <div className="small-print">
            {returnCMS(
              pageCMS,
              27,
              "By clicking “Proceed to payment” you agree to our"
            )}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={returnCMS(
                pageCMS,
                28,
                "https://uploads-ssl.webflow.com/621cc721d36bc65c26571c8e/6318c501b513b5957de65b32_Privacy%20Policy%20Branded.pdf"
              )}
            >
              {returnCMS(pageCMS, 29, "terms and conditions")}
            </a>{" "}
            {returnCMS(
              pageCMS,
              30,
              "including our supervision policy, and having your data handled in accordance to our"
            )}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={returnCMS(
                pageCMS,
                39,
                "https://uploads-ssl.webflow.com/621cc721d36bc65c26571c8e/6318c501b513b5957de65b32_Privacy%20Policy%20Branded.pdf"
              )}
            >
              {returnCMS(pageCMS, 40, "privacy policy")}
            </a>
            {returnCMS(
              pageCMS,
              41,
              ". After signing up you will receive communication regarding your purchase and occasional marketing emails, which you may unsubscribe from at any time - we do not spam."
            )}
          </div>
        </div>
        {/* </div> */}
        <WorkshopBlock deviceLarge={deviceLarge} />
      </form>
    );
  } else {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>
        <h1 className="blue padded">
          {returnCMS(pageCMS, 34, "Signups are currently closed :(")}
        </h1>
        <div className="signup-block">
          <p style={{ textAlign: "center" }}>
            {returnCMS(
              pageCMS,
              35,
              "We're at capacity right now unfortunately. We'll be in touch as soon as there are more kits available! If you're new here sign up to our waitlist."
            )}
          </p>
          <SubmitButton
            onClick={() => {
              window.location.href = "/";
            }}
            text={returnCMS(pageCMS, 36, "Sign up to our waitlist")}
            loading={isLoading}
            complete={isComplete}
            width={285}
          />
        </div>
      </div>
    );
  }
}

export default PrepurchaseSignup;
