import { BsCheckCircle } from "react-icons/bs";
import { MdOutlineCheckCircle } from "react-icons/md";
import GearLoader from "./gearLoader";

function SubmitButton(props: {
  onClick: () => void;
  text: string;
  complete?: boolean;
  loading?: boolean;
  width: number;
}) {
  return (
    <button
      className="submit-button"
      style={{ width: props.width }}
      onClick={props.onClick}
      disabled={props.loading || props.complete}
    >
      {props.complete ? (
        <MdOutlineCheckCircle size={36} color="white" />
      ) : props.loading ? (
        <div>
          <GearLoader color="white" />
        </div>
      ) : (
        <>
          <div>{props.text}</div>
          <div className="submit-arrow">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.333 6.66669L26.6663 16L17.333 25.3334M5.33301 16H26.6663H5.33301Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </>
      )}
    </button>
  );
}

export default SubmitButton;
