import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { BiInfoCircle } from "react-icons/bi";
import WorkshopBlock from "../elements/workshopBlock";
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { getPageCMS, returnCMS } from "../utils/cmsUtils";
import SubmitButton from "../elements/submitButton";
import GearLoader from "../elements/gearLoader";
import { AddressFinder } from "@ideal-postcodes/address-finder";
import { logout } from "../utils/auth";

type UserCheckoutProps = {
  deviceLarge: boolean;
  dev?: boolean;
  env?: "local" | "stag" | "prod";
  // pageVersion: 1 | 2 | 3 | 4;
};

function UserCheckout({ deviceLarge, dev, env }: UserCheckoutProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [discountCode, setDiscountCode] = useState("");
  const [promoCodeId, setPromoCodeId] = useState<null | string>(null);
  const [acceptZWaste, setAcceptZWaste] = useState(false);
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    country: "United Kingdom",
    postal_code: "",
  });
  const [addressPostcode, setAddressPostcode] = useState("");
  const [usersUncompletedKits, setUsersUncompletedKits] = useState(1);
  const [discountPercentOff, setDiscountPercentOff] = useState<null | number>(
    null
  );
  const [discountAmountOff, setDiscountAmountOff] = useState<null | number>(
    null
  );
  const [childname, setChildname] = useState("Fixer");
  const [estimatedDispatchDate, setEstimatedDispatchDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [totalCost, setTotalCost] = useState("0");
  const [discountEntryOpen, setDiscountEntryOpen] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const [isSALoading, setIsSALoading] = useState(false);
  const [isSAComplete, setIsSAComplete] = useState(false);

  const [planSelected, setPlanSelected] = useState(1);
  const [displayedPrice, setDisplayedPrice] = useState<{
    [key: string]: number;
  }>({ "1": 0.0, "2": 0.0 });

  const [errorMessage, setErrormessage] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [discountErrorMessage, setDiscountErrormessage] = useState("");
  const [pageCMS, setPageCMS] = useState<{ [key: string]: any } | null>(null);

  const [checkoutOpen, setCheckoutOpen] = useState(true);
  const [editingAddress, setEditingAddress] = useState(false);
  const [badAddress, setBadAddress] = useState(false);

  const SERVER_URL =
    env === "stag"
      ? "https://dev-api.teamrepair.dev"
      : env === "prod"
      ? "https://api.teamrepair.dev"
      : "http://localhost:8000";

  const numAvailableKits = 2;

  let cmsCounter = 3;

  useEffect(() => {
    window.addEventListener("input", () => {
      setErrormessage("");
    });
    reloadTotalCost();
    loadDiscountFromURL();
    getPageCMS("user-checkout", "https://api.teamrepair.dev").then((cms) => {
      reloadUserData();
      setPageCMS(cms);
      console.log(cms);
      if (
        returnCMS(cms, 0, "checkout-closed") !== "checkout-open"
        //  &&
        // env == "prod"
      ) {
        setCheckoutOpen(false);
      }
      setDisplayedPrice({
        "1": parseFloat(returnCMS(cms, 1, "27.99")),
        "2": parseFloat(returnCMS(cms, 2, "25.99")),
      });
    });
  }, []);

  useEffect(() => {
    reloadTotalCost();
  }, [planSelected, discountAmountOff, discountPercentOff, displayedPrice]);

  useEffect(() => {
    if (!pageLoading && checkoutOpen) {
      AddressFinder.setup({
        apiKey: process.env.REACT_APP_ADDRESS_API_KEY as string,
        containerStyle: {
          width: "100%",
        },
        defaultCountry: "GBR",
        detectCountry: true,
        restrictCountries: ["GBR"],
        outputFields: {
          line_1: "#line_1",
          line_2: "#line_2",
          post_town: "#post_town",
          postcode: "#postcode",
        },
      });
    }
  }, [pageLoading]);

  function loadDiscountFromURL() {
    const code = searchParams.get("code");
    if (code) {
      setDiscountEntryOpen(true);
      setDiscountCode(code);
      console.log(code);
      checkDiscountCode(code);
    }
  }

  function reloadTotalCost() {
    if (discountAmountOff) {
      setTotalCost(
        (
          displayedPrice[planSelected.toString()] * planSelected -
          discountAmountOff
        ).toFixed(2)
      );
    } else if (discountPercentOff) {
      setTotalCost(
        (
          displayedPrice[planSelected.toString()] *
          planSelected *
          ((100 - discountPercentOff) / 100)
        ).toFixed(2)
      );
    } else {
      setTotalCost(
        (displayedPrice[planSelected.toString()] * planSelected).toFixed(2)
      );
    }
  }

  function reloadUserData() {
    // All fields must have data
    setPageLoading(true);

    const token = localStorage.getItem("token");

    if (token === null) {
      window.location.href = "/login";
    }

    fetch(SERVER_URL + "/web/getuserdataforcheckout", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          return res.json().then((data) => {
            if (data["success"] || data["error"]) {
              data.status = res.status;
              return data;
            } else {
              throw res;
            }
          });
        }
      })
      .then((data) => {
        if (data["success"]) {
          console.log(data["user_data"]["shipping_details"]);
          if (data["user_data"]["shipping_details"] === null) {
            setEditingAddress(true);
            setBadAddress(true);
          } else {
            setAddress(data["user_data"]["shipping_details"]["address"]);
            setAddressPostcode(
              data["user_data"]["shipping_details"]["address"]["postal_code"]
            );
          }
          setEstimatedDispatchDate(
            data["user_data"]["estimated_shipping_date"]
          );
          setChildname(data["user_data"]["childs_name"]);
          setFirstName(data["user_data"]["first_name"]);
          setLastName(data["user_data"]["last_name"]);
          setUsersUncompletedKits(
            numAvailableKits - data["user_data"]["number_boxes"]
          );
          setPageLoading(false);
        } else if (data["error"]) {
          setErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        err.status
          ? setErrormessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setErrormessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  function submitForm() {
    // All fields must have data
    setIsLoading(true);

    if (!acceptZWaste) {
      setErrormessage(
        "Please accept the zero waste commitment terms and conditions to continue."
      );
      setIsLoading(false);
      return false;
    }

    if (badAddress) {
      setErrormessage("Please update your shipping address before continuing.");
      setIsLoading(false);
      return false;
    }

    let redirect_url: URL;

    const token = localStorage.getItem("token");

    fetch(SERVER_URL + "/web/createreordersession", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        promo_code_id: promoCodeId,
        num_kits: planSelected,
        source_url: window.location.href,
        discount_code: discountCode,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setIsLoading(false);
          setIsComplete(true);
          // localStorage.setItem("customer_email", email);
          window.location.href = data.url;
        } else if (data["error"]) {
          setIsLoading(false);
          setErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          setIsLoading(false);
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        err.status
          ? setErrormessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setErrormessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  function checkDiscountCode(code?: string) {
    let codeToCheck;
    if (code) {
      codeToCheck = code;
    } else {
      codeToCheck = discountCode;
    }

    if (codeToCheck === "") {
      setPromoCodeId(null);
      return false;
    }

    fetch(SERVER_URL + "/web/verifycouponcode", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: codeToCheck,
      }),
    })
      .then((res) => {
        return res.json().then((data) => {
          if (data["success"] || data["error"]) {
            data.status = res.status;
            return data;
          } else {
            throw res;
          }
        });
      })
      .then((data) => {
        if (data["success"]) {
          setPromoCodeId(data["promo_code_id"]);
          if (data["percent_off"] != null) {
            setDiscountPercentOff(data["percent_off"]);
          } else if (data["amount_off"] != null) {
            setDiscountAmountOff(data["amount_off"] / 100);
          } else {
            setPromoCodeId(null);
            setDiscountErrormessage("Something went wrong, please try again.");
          }
          return reloadTotalCost();
        } else if (data["error"]) {
          setDiscountErrormessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        err.status
          ? setDiscountErrormessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setDiscountErrormessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  function saveAddress() {
    // All fields must have data
    setIsSALoading(true);
    const token = localStorage.getItem("token");

    if (address.line1 === "" || addressPostcode === "" || address.city === "") {
      setBadAddress(true);
      setIsSALoading(false);
      return false;
    }

    fetch(SERVER_URL + "/web/updateshippingaddress", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_address: address,
        postcode: addressPostcode,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          logout();
          return false;
        } else {
          return res.json().then((data) => {
            if (data["success"] || data["error"]) {
              data.status = res.status;
              return data;
            } else {
              throw res;
            }
          });
        }
      })
      .then((data) => {
        if (data["success"]) {
          setAddress(data["shipping_details"]["address"]);
          setAddressPostcode(
            data["shipping_details"]["address"]["postal_code"]
          );
          setIsSALoading(false);
          setEditingAddress(false);
          setBadAddress(false);
        } else if (data["error"]) {
          setIsSALoading(false);
          setAddressErrorMessage(
            data.error.message + " (code: " + data.error.trace_id + ")"
          );
        } else {
          setIsSALoading(false);
          throw new Error();
        }
      })
      .catch((err) => {
        setIsSALoading(false);
        err.status
          ? setAddressErrorMessage(
              "Error reaching server, please try again later - " + err.status
            )
          : setAddressErrorMessage(
              "Error reaching server, please try again later - unknown"
            );
      });
  }

  if (checkoutOpen && !pageLoading) {
    return (
      <form
        className="signup-container"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="navbar-spacing"></div>
        <h1 className="blue padded">
          Purchase more kits!{" "}
          {env === "stag" || env === "local" ? "(" + env + ")" : null}
        </h1>
        <div className="box-block">
          <img src="/hero.jpg" alt="" className="box-image" />
          <div className={`box-name ${childname.length > 12 && "large"}`}>
            {childname
              ? deviceLarge
                ? childname.slice(0, 17)
                : childname.slice(0, 16)
              : "Fixer"}
          </div>
          <div className="price-s-print">
            Picture for illustrative purposes only, delivered box may look
            different.
          </div>
        </div>
        <div
          className="signup-block"
          style={{ overflow: "visible", width: "-webkit-fill-available" }}
        >
          <div className="block-header edit-address">
            <h2>Your shipping address:</h2>
            <button
              onClick={(event) => {
                event.preventDefault();
                setEditingAddress(!editingAddress);
              }}
              className="pill secondary"
            >
              {!editingAddress ? "Edit" : "Cancel"}
            </button>
          </div>
          <div
            className="edit-address-block"
            style={!editingAddress ? { maxHeight: 0, overflow: "hidden" } : {}}
          >
            <input
              type="text"
              name="line_1"
              id="line_1"
              autoComplete="address-line1"
              placeholder="Address first line"
              className="required wide"
              onChange={(event) =>
                setAddress((address) => ({
                  ...address,
                  ...{ line1: event.target.value },
                }))
              }
              value={address.line1}
              required
            />
            <input
              type="text"
              name="line_2"
              id="line_2"
              autoComplete="address-line2"
              placeholder="Address second line"
              className="wide"
              onChange={(event) =>
                setAddress((address) => ({
                  ...address,
                  ...{ line2: event.target.value },
                }))
              }
              value={address.line2}
            />
            <input
              type="text"
              name="post_town"
              id="post_town"
              autoComplete="address-level1"
              placeholder="City"
              className="required"
              onChange={(event) =>
                setAddress((address) => ({
                  ...address,
                  ...{ city: event.target.value },
                }))
              }
              value={address.city}
              required
            />
            <input
              type="text"
              name="postcode"
              id="postcode"
              autoComplete="postal-code"
              placeholder="Postcode"
              className="required"
              onChange={(event) => setAddressPostcode(event.target.value)}
              value={addressPostcode}
              required
            />
            {addressErrorMessage ? (
              <div style={{ width: "100%" }}>
                <div className="address-error-message">
                  {addressErrorMessage}
                </div>
              </div>
            ) : null}
            <SubmitButton
              onClick={saveAddress}
              text={badAddress ? "Save address" : "Update address"}
              loading={isSALoading}
              complete={isSAComplete}
              width={244}
            />
          </div>
          <div
            className="address-block"
            style={editingAddress ? { maxHeight: 0, overflow: "hidden" } : {}}
          >
            <div>{address.line1}</div>
            {address.line2 ? <div>{address.line2}</div> : null}
            <div>{address.city}</div>
            <div>{address.country}</div>
            <div>{addressPostcode}</div>
          </div>
        </div>
        {usersUncompletedKits > 0 ? (
          <>
            <div className="signup-block">
              <h2 className="block-header">
                Confirm you will return your kits
              </h2>
              <div className="max-width-block">
                As part of our aim to be the world's most sustainable
                subscription, we include pre-paid return labels for our
                customers to return their kits for reuse. By doing this, you can
                reduce the clutter of forgotten toys and help create zero waste
                for our planet 🌍.
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 16,
                  boxSizing: "border-box",
                }}
                className="max-width-block"
              >
                <input
                  type="checkbox"
                  name="zero-waste"
                  checked={acceptZWaste}
                  onChange={(event) => {
                    setAcceptZWaste(event.target.checked);
                  }}
                />
                <div
                  onClick={() => {
                    setAcceptZWaste((value) => !value);
                  }}
                  style={{ width: "fit-content", cursor: "pointer" }}
                >
                  I will return my kits <b>within a month</b>, & agree to the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={returnCMS(
                      pageCMS,
                      cmsCounter++,
                      "https://team.repair/terms-and-conditions.html#returns"
                    )}
                  >
                    zero-waste commitment.
                  </a>
                  .
                </div>
              </div>
            </div>
            <div
              className={`signup-block ${
                discountEntryOpen ? "" : "collapsed"
              } discount`}
              style={!discountEntryOpen ? { maxHeight: 52 } : {}}
            >
              <div
                className="block-header with-expand"
                onClick={() => setDiscountEntryOpen(!discountEntryOpen)}
              >
                <h2>Add a discount code</h2>
                <MdOutlineExpandMore
                  size={32}
                  className={`expand-arrow ${discountEntryOpen ? "close" : ""}`}
                />{" "}
              </div>
              {promoCodeId === null ? (
                <div className="discount-field-container">
                  <input
                    type="text"
                    name="discount"
                    placeholder="Discount code"
                    className="wide discount"
                    id="discount"
                    onChange={(event) => {
                      setDiscountCode(event.target.value);
                      setDiscountErrormessage("");
                    }}
                    value={discountCode}
                  />
                  <button
                    className="discount-button"
                    onClick={(event) => {
                      event.preventDefault();
                      checkDiscountCode();
                    }}
                  >
                    <div>Apply</div>
                  </button>
                </div>
              ) : (
                <div
                  className="discount-field-container added"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>
                    Promo code added:
                    {discountCode}
                  </h3>
                  <IoMdCloseCircle
                    size={32}
                    onClick={() => {
                      setDiscountCode("");
                      setPromoCodeId(null);
                      setDiscountErrormessage("");
                      setDiscountAmountOff(null);
                      setDiscountPercentOff(null);
                    }}
                    className="close-icon"
                  />
                </div>
              )}
              {discountErrorMessage ? (
                <div className="discount-error-message">
                  {discountErrorMessage}
                </div>
              ) : null}
            </div>
            <div className="signup-block">
              <h2 className="block-header">
                How many more months would you like?
              </h2>
              <div className="max-width-block">
                Since we're still scaling, only the first {numAvailableKits}{" "}
                months of our programme are available. You've got{" "}
                {usersUncompletedKits} more uncompleted kit available to
                purchase 🚀!
              </div>

              <div className="plan-button-cont">
                <button
                  className={
                    planSelected === 1 ? "plan-button-checked" : "plan-button"
                  }
                  type="button"
                  onClick={() => {
                    setPlanSelected(1);
                  }}
                >
                  {" "}
                  <div>
                    <div>1 Kit</div>
                    <div style={{ fontWeight: 300, marginTop: 2 }}>
                      £{displayedPrice[1]}/kit
                    </div>
                  </div>
                </button>
                <input
                  type="radio"
                  checked={planSelected === 1}
                  value="1"
                  readOnly
                  name="PLAN"
                  id="mce-PLAN-0"
                  style={{ display: "none" }}
                />

                <button
                  className={
                    planSelected === 2 ? "plan-button-checked" : "plan-button"
                  }
                  disabled={usersUncompletedKits < 2}
                  type="button"
                  onClick={() => {
                    setPlanSelected(2);
                  }}
                >
                  {usersUncompletedKits >= 2 ? (
                    <>
                      <div>
                        <div>2 Kits</div>
                        <div style={{ fontWeight: 300, marginTop: 2 }}>
                          £{displayedPrice[2]}/kit
                        </div>
                      </div>
                      <div className="button-bubble">14% off</div>
                    </>
                  ) : (
                    <div>
                      <div>Not available</div>
                      <div style={{ fontWeight: 300, marginTop: 2 }}>
                        You're on your last kit! 🤯
                      </div>
                    </div>
                  )}
                </button>
                <input
                  type="radio"
                  checked={planSelected === 2}
                  value="2"
                  readOnly
                  name="PLAN"
                  id="mce-PLAN-2"
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="price-block">
              <div className="price-row">
                <div>
                  <em>Cost per box:</em>
                </div>
                <div>£{displayedPrice[planSelected.toString()].toFixed(2)}</div>
              </div>
              <div className="price-row">
                <div>
                  <em>Postage & Return:</em>
                </div>
                <div>Free</div>
              </div>
              {discountAmountOff || discountPercentOff ? (
                <div className="price-row">
                  <div>
                    <em>
                      Discount code ({discountCode}
                      ):
                    </em>
                  </div>
                  {discountAmountOff ? (
                    <div>-£{discountAmountOff.toFixed(2)}</div>
                  ) : (
                    discountPercentOff && (
                      <div>
                        -£
                        {(
                          displayedPrice[planSelected.toString()] *
                          planSelected *
                          (discountPercentOff / 100)
                        ).toFixed(2)}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="price-row" style={{ fontWeight: "600" }}>
                <div>
                  <em>Total:</em>
                </div>
                <div>£{totalCost}</div>
              </div>
              {planSelected == 1 ? (
                <div className="price-s-print">
                  The kit must be sent back within a month and before further
                  kits can be purchased.
                </div>
              ) : (
                <div className="price-s-print">
                  The kits will be posted one by one, approximately monthly. We
                  must have received the previous kit before the next kit is
                  dispatched.
                </div>
              )}
              <div
                className="price-row"
                style={{ fontWeight: "600", marginTop: 8 }}
              >
                <div></div>
                <div>
                  Estimated dispatch of your next kit:{" "}
                  {new Date(estimatedDispatchDate).toLocaleDateString()}
                </div>
              </div>
            </div>

            <div className="signup-block">
              {errorMessage ? (
                <div className="error-message">{errorMessage}</div>
              ) : null}
            </div>
            <SubmitButton
              onClick={submitForm}
              text="Proceed to payment"
              loading={isLoading}
              complete={isComplete}
              width={264}
            />
          </>
        ) : (
          <div className="signup-block">
            <h2 className="block-header">
              You've completed all available kits!
            </h2>
            <div className="max-width-block">
              Since we're still scaling, only the first {numAvailableKits}{" "}
              months of our programme are available. That means you've completed
              all our available kits for now 😔. But don't worry, more will be
              released soon, and we'll make sure you're first to know 🚀!
            </div>
          </div>
        )}
        <div style={{ height: 44 }}></div>
      </form>
    );
  } else if (checkoutOpen && pageLoading) {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>
        <div className="signup-block">
          <h2
            className="blue padded"
            style={{ textAlign: "center", marginBottom: 24 }}
          >
            Just grabbing a few details...
          </h2>
          <div>
            <GearLoader large color="#5a8eff" />
          </div>
        </div>
        <div className="signup-block">
          {errorMessage ? (
            <div className="error-message">{errorMessage}</div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div className="signup-container">
        <div className="navbar-spacing"></div>
        <h1 className="blue padded">We're at capacity :(</h1>
        <div className="signup-block">
          <p style={{ textAlign: "center" }}>
            Unfortunately we're at capacity right now. We'll be in touch as soon
            as there are more kits available for you to purchase!
          </p>
        </div>
        <SubmitButton onClick={logout} text={"Logout"} width={160} />
      </div>
    );
  }
}

export default UserCheckout;
