import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import SubmitButton from "../elements/submitButton";

type UserDashboardProps = {
  deviceLarge: boolean;
  dev?: boolean;
  env?: "local" | "stag" | "prod";
};

function UserDashboard({ deviceLarge, dev, env }: UserDashboardProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  let navigate = useNavigate();

  const SERVER_URL =
    env === "stag"
      ? "https://dev-api.teamrepair.dev"
      : env === "prod"
      ? "https://api.teamrepair.dev"
      : "http://localhost:8000";

  const token = localStorage.getItem("token");

  function getBillingLink() {
    const url = SERVER_URL + "/web/getbillingportal";
    setIsLoading(true);

    fetch(url, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          throw new Error("Unable to get billing link");
        }
        const data: any = res.json();
        return data;
      })
      .then((data) => {
        setIsLoading(false);
        if (data["success"] && data["url"]) {
          window.open(data["checkout_url"], "_blank");
        } else {
          setErrorMessage(data["detail"]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message);
      });
  }

  return (
    <div className="centered-page-container">
      <SubmitButton
        onClick={() => {
          getBillingLink();
        }}
        text={"Get Billing Link"}
        loading={isLoading}
        complete={isComplete}
        width={160}
      />
      <div className="signup-block">
        {errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : null}
      </div>
    </div>
  );
}

export default UserDashboard;
