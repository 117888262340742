import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GearLoader from "../elements/gearLoader";
import SubmitButton from "../elements/submitButton";
import getMobileOperatingSystem from "../utils/detectPlatform";
import logo from "./logo.svg";

type DownloadProps = {
  deviceLarge: boolean;
  dev?: boolean;
  env?: "local" | "stag" | "prod";
};

function Download({ deviceLarge, dev, env }: DownloadProps) {
  return (
    <div className="signup-container">
      <div className="navbar-spacing"></div>

      <h1 className="blue padded">Download our app!</h1>
      <div className={(deviceLarge ? "center" : "") + " padded"}>
        Our app is essential to guide you through your kit and provides extra learning content for the full Team Repair experience!
      </div>

      <div className="app-icon-cont" style={{ marginTop: 48 }}>
        <img src={"/app-icon.png"} alt="Team Repair" className="app-icon" />
      </div>
      <div className="appstore-title-cont padded">
        <h2 className="center">Team Repair</h2>
        <div>Box Guides and Digital Content</div>
      </div>
      <div className="appstore-logos-container">
        {getMobileOperatingSystem() === "iOS" ||
        getMobileOperatingSystem() === "unknown" ? (
          <a
            href="https://apps.apple.com/gb/app/team-repair/id1660930642?itsct=apps_box_badge&amp;itscg=30200"
            className="appstore-cont"
          >
            <img
              src={"/apple-appstore.png"}
              alt="Download on the App Store"
              className="appstore-cont"
            />
          </a>
        ) : null}
        {getMobileOperatingSystem() === "Android" ||
        getMobileOperatingSystem() === "unknown" ? (
          <a
            className="appstore-cont"
            href="https://play.google.com/store/apps/details?id=repair.team.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              src={"/android-appstore.png"}
              alt="Get it on Google Play"
              className="appstore-cont"
            />
          </a>
        ) : null}
        {getMobileOperatingSystem() === "Amazon" ||
        getMobileOperatingSystem() === "unknown" ? (
          <a
            className="appstore-cont"
            href="https://www.amazon.co.uk/Team-Repair-Limited/dp/B0BW9RV78P/ref=sr_1_1?keywords=team+repair&qid=1677699413&s=mobile-apps&sr=1-1"
          >
            <img
              src={"/amazon-appstore.png"}
              alt="Available at Amazon"
              className="appstore-cont"
            />
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default Download;
