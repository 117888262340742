import React, { useState, useEffect } from "react";
import HeaderBlock from "../elements/headerBlock";

type ReturnsProps = {
  deviceLarge: boolean;
};


function Returns({ deviceLarge }: ReturnsProps) {

  return (
    <div>
      <HeaderBlock deviceLarge={deviceLarge} pageName="returns" />
      <div className={`page-cont ${deviceLarge ? "" : "mob"}`}>
        <div className={`returns-steps-grid ${deviceLarge ? "" : "mob"}`}>
          <div className="return-step-cont pink">
            <div>
              <h2 className="pink">1. Pack the box</h2>
              <p>The packing list is on the inside of the lid. Place the gadget (with battery removed), tools, and project equipment in the box. You can keep the paperwork!</p>
            </div>
            <img style={{maxWidth: "100%"}} src="pack-box.jpg"></img>
          </div>
          <div className="return-step-cont green">
            <div>
              <h2 className="green">2. Attach Return Label</h2>
              <p>The pre-paid returns label is underneath the box tray. Attach by wrapping it around so that it seals the box closed. <b>Make sure it covers the original label, especially your address so that this doesn’t get confused with the return address!</b></p>
            </div>
            <img style={{maxWidth: "100%"}} src="shipping-label-short.gif"></img>
          </div>
          <div className="return-step-cont navy">
            <div>
              <h2 className="navy">3. Drop off</h2>
              <p>Now you can just drop it off at any Evri retailer. You can use <a href="https://www.evri.com/find-a-parcelshop#/">this link</a> to find your closest drop off location!</p>
            </div>
            <img style={{maxWidth: "100%"}} src="evri-label.jpeg"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Returns;
