import React, { useState, useEffect } from "react";
import HeaderBlock from "../elements/headerBlock";
import { MdOutlineExpandMore } from "react-icons/md";

type SupportProps = {
  deviceLarge: boolean;
};

const faqs: {[key: string]: string} = {
  "How can I buy multiple kits?": "Every fixer gets their own Team Repair account - so every time you sign a new one up you need to input a new email.", 
  "How do I download the app?": "Our phone and tablet app is available on Apple's App Store, Google's Playstore, and Amazon's Firestore. Just search Team Repair, or go to kits.team.repair/download on your chosen device to get the link.", 
  "How do I login to the app?": "When you ordered your kit, you were sent an email to complete the account setup. Those details will sign you into the app. If you can't find them, please reach out to support.", 
  "What is my kit unlock code?": "The kit unlock code was included in your kit's dispatch email. If you can't find it, please reach out to support.", 
  "Where are the extension activities?": "The extension activites give fixers hours more fun with each team repair kit. They can be accessed via the tutorial page once you finish the core activity.", 
  "I have recieved this kit before": "Oh no! If you have recieved a kit you did before in a school or workshop, reach out to support so we can send you a new one.", 
  "How do I return the kit?": "To see our step-by-step return guide, please vist kits.team.repair/return.", 
  "When do I get my next kit": "If you ordered multiple kits, your next one will arrive 30 days from the last one arriving, or 1 week after you returned the last one, whichever is later. If you didn't order multiple kits, you will be emailed asking if you would like to recieve the next one.",
}


function Support({ deviceLarge }: SupportProps) {

  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const toggleAccordion = (title: string) => {
    if (openAccordion === title) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(title);
    }
  };
  
  return (
    <div>
      <HeaderBlock deviceLarge={deviceLarge} pageName="support" />
      <div className={`page-cont ${deviceLarge ? "" : "mob"}`}>
      <div className={`faq-cont ${deviceLarge ? "" : "mob"}`}>
          {Object.keys(faqs).map((title) => (
            <div className="accordion" key={title}>
              <div className="accordion-header" onClick={() => toggleAccordion(title)}>
                <h3>{title}</h3>
                <MdOutlineExpandMore
                  size={32}
                  className={'expand-arrow ' + ((openAccordion === title) ? 'close' : 'open')} />
              </div>
              <div className="accordion-content"
              style={{
                height: openAccordion === title ? 'auto' : 0,
                display: (openAccordion === title) ? 'block' : 'none',
                overflow: 'hidden',
                transition: 'height 0.3s ease',
              }}>
                {faqs[title]}
              </div>
            </div>
          ))}
        </div>
              
        <div className={`contact-details-cont ${deviceLarge ? "" : "mob"}`}>
          <h2 className="navy">Fixer Hotline</h2>
          <div>
            <h3>☎️ 020 8611 2037</h3>
            <p style={{margin: 0}}>Open between 9am-6pm everyday.</p>
          </div>
          <div>
            <h3>📧  <a href="mailto:support@team.repair" style={{textDecoration:"none"}}>support@team.repair</a></h3>
            <p style={{margin: 0}}>We aim to get back to you within 12 hours.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Support;
