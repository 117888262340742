import React from "react";
import logo from "./logo.svg";

interface pages {
  [key: string]: string[];
}

function HeaderBlock({deviceLarge, pageName} : {deviceLarge:boolean, pageName:string}) {
  const pages:pages = {
    "support": 
      ["Support", 
      "If you are having trouble with the tutorials, payment, returns, or any other part of the Team Repair experience, check out the FAQs or reach out by email or phone.",
      "workshop-fade.webp",
    ],
    "returns": 
      ["Returns", 
      "Because our kits reused over and over again, Team Repair is the first truly sustainble subscription service. To make this possible, we ask our fixers to return their kits before recieving the next one. Don't worry though, its super easy!",
      "workshop-fade.webp",
    ],
  }
  return (
    <div className="workshop-block">
      <img
        src={deviceLarge ? pages[pageName][2] : "workshop.webp"}
        alt=""
        className="workshop-image"
      />
      <div className="workshop-text-block">
        <h1 className={deviceLarge ? "blue right" : "blue"}>
          {pages[pageName][0]}
        </h1>
        <div>
          {pages[pageName][1]}
        </div>
      </div>
    </div>
  );
}

export default HeaderBlock;
