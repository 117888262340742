import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import SubmitButton from "../elements/submitButton";

type LoginProps = {
  deviceLarge: boolean;
  dev?: boolean;
  env?: "local" | "stag" | "prod";
};

function Login({ deviceLarge, dev, env }: LoginProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  let navigate = useNavigate();

  const SERVER_URL =
    env === "stag"
      ? "https://dev-api.teamrepair.dev"
      : env === "prod"
      ? "https://api.teamrepair.dev"
      : "http://localhost:8000";

  function login() {
    const url = SERVER_URL + "/auth/login/";
    setIsLoading(true);

    fetch(url, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          throw new Error("Invalid username or password!");
        }
        const data: any = res.json();
        return data;
      })
      .then((data) => {
        setIsLoading(false);
        if (data["access"]) {
          localStorage.setItem("token", data["access"]);
          navigate("/user/checkout");
          setIsComplete(true);
        } else {
          setErrorMessage(data["detail"]);
        }
      }).catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message);
      })
  }

  return (
    <form
      className="centered-page-container"
      onSubmit={(event) => {
        event.preventDefault();
        login();
      }}
    >
      <div className="navbar-spacing"></div>

      <h2
        className="page-title blue"
        style={{ textAlign: "center", paddingBottom: 16 }}
      >
        Please login
      </h2>
      <div className="signup-block">
        <input
          type="text"
          name="CNAME"
          placeholder="Email"
          id="mce-CNAME"
          className="wide"
          onChange={(event) => setEmail(event.target.value.toLowerCase())}
          value={email}
        />
        <input
          type="password"
          placeholder="Password"
          className="wide"
          onChange={(event) => setPassword(event.target.value)}
          value={password}
        />
      </div>
      <SubmitButton
        onClick={() => {
          login();
        }}
        text={"Login"}
        loading={isLoading}
        complete={isComplete}
        width={160}
      />
      <div className="signup-block">
        {errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : null}
      </div>
    </form>
  );
}

export default Login;
