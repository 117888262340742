import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import { logout } from "../utils/auth";

function Navbar() {
  const [userPage, setUserPage] = useState(false);
  useEffect(() => {
    const url = window.location.href;
    if (url.includes("/user/")) {
      setUserPage(true);
    }
  }, []);

  return (
    <div className="navbar-cont">
      <div className="navbar-content-cont">
        <a href="https://team.repair/">
          <img
            src="https://team.repair/images/logo2.webp"
            alt=""
            className="navbar-logo"
          />
        </a>
        {userPage ? <button className="pill" onClick={logout}>Logout</button> : <div></div>}
      </div>
    </div>
  );
}

export default Navbar;
