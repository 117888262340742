import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import WorkshopBlock from "../elements/workshopBlock";
import logo from "./logo.svg";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineCheckCircle, MdOutlineCheckCircleOutline, MdOutlinePendingActions } from "react-icons/md";

type StripeRedirectProps = {
  deviceLarge: boolean;
};

function StripeRedirect({ deviceLarge }: StripeRedirectProps) {
  const [email, setEmail] = useState("");
  useEffect(() => {
    const email = localStorage.getItem("customer_email");
    if (email) {
      setEmail(email);
    }
  }, []);

  return (
    <div className="signup-container">
      <div className="navbar-spacing"></div>
      <div className="signup-inner-container">
        <div
          className="success-message"
          style={{
            display: "flex",
            backgroundColor: "#f2f2f2",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
            marginBottom: 24,
          }}
        >
          {/* <MdOutlinePendingActions size={48} color="#2b303a" /> */}
          <h2 style={{ color: "#2b303a", opacity:0.4, display:"flex", flexDirection:"row", gap:8, alignItems:"center", justifyContent:"center"  }}>Payment Confirmed <MdOutlineCheckCircle size={40} color="#2b303a" /></h2>
          <h2 style={{ color: "#2b303a", display:"flex", flexDirection:"row", gap:8, alignItems:"center", justifyContent:"center"  }}>Order Pending <MdOutlinePendingActions size={40} color="#2b303a" /></h2>
        </div>
        {/* <h1 className="blue padded"></h1> */}
        <h3 className={deviceLarge ? "center" : ""}>
          Thank you, your payment has been received! We’re so excited to send you your first kit, but before we send it just one more thing...
        </h3>
        <h2 className={(deviceLarge ? "center" : "") + " blue"}>
          Please check your inbox to complete your account!
        </h2>
        {email?
        <div className="">You signed up with: {email}</div>
        :null}
      </div>
    </div>
  );
}

export default StripeRedirect;
