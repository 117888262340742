async function getPageCMS(pageSlug: string, serverUrl: string) {
  //get the cms JSON object for a specific page id. CMS values are stored like { "body": [{value: "CMS value", ...}, ...]}
  var options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  // console.log(serverUrl + `/cms/api/pages/`);
  return fetch(serverUrl + `/cms/api/pages/?slug=${pageSlug}`, options)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    })
    .then((resJSON) => {
      //return cms as JSON object
      const pageId = resJSON.items[0].id;
      return fetch(serverUrl + `/cms/api/pages/${pageId}/`, options);
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res;
      }
    })
    .then((resJSON) => {
      //return cms as JSON object
      return resJSON;
    })
    .catch((err) => {
      // if (err.status === 401) {
      //   //unauthorised - refresh access token then retry cms
      //   return refreshAccessToken(navigation).then(() => {
      //     console.log("access token refreshed, retrying cms");
      //     getPageCMS(id, navigation);
      //   });
      // } else
      if (
        err.message === "Timeout" ||
        err.message === "Network request failed"
      ) {
        //timed out
        console.log("cms timed out");
        return null;
      } else if (err.status === 404) {
        //timed out
        console.log("cms id not found");
        return null;
      } else {
        //other errors
        return null;
      }
    });
}

function returnCMS(cms: any, id: number, backup: string) {
  if (cms) {
    if (cms.body[id]) {
      return cms.body[id].value;
    }
  }

  return backup;
}

export { getPageCMS, returnCMS };
