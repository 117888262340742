import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import WorkshopBlock from "../elements/workshopBlock";
import logo from "./logo.svg";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineCheckCircle, MdOutlineCheckCircleOutline, MdOutlinePendingActions } from "react-icons/md";
import SubmitButton from "../elements/submitButton";
import { logout } from "../utils/auth";

type UserStripeRedirectProps = {
  deviceLarge: boolean;
};

function UserStripeRedirect({ deviceLarge }: UserStripeRedirectProps) {
  const [email, setEmail] = useState("");
  useEffect(() => {
    const email = localStorage.getItem("customer_email");
    if (email) {
      setEmail(email);
    }
  }, []);

  return (
    <div className="signup-container">
      <div className="navbar-spacing"></div>
      <div className="signup-inner-container">
        <div
          className="success-message"
          style={{
            display: "flex",
            backgroundColor: "#ECF8F2",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
            marginBottom: 24,
          }}
        >
          {/* <MdOutlinePendingActions size={48} color="#2b303a" /> */}
          <h2 style={{ color: "#44B97D", display:"flex", flexDirection:"row", gap:8, alignItems:"center", justifyContent:"center"  }}>Order Confirmed <MdOutlineCheckCircle size={40} color="#44B97D" /></h2>
        </div>
        {/* <h1 className="blue padded"></h1> */}
        <h3 className={deviceLarge ? "center" : ""}>
          Thank you, your order is confirmed! We'll be in touch as soon as it's ready to dispatch!
        </h3>
      </div>
    </div>
  );
}

export default UserStripeRedirect;
