import React from "react";
import logo from "./logo.svg";

function WorkshopBlock({deviceLarge} : {deviceLarge: boolean}) {
  return (
    <div className="workshop-block">
      <img
        src={deviceLarge ? "workshop-fade.webp" : "workshop.webp"}
        alt=""
        className="workshop-image"
      />
      <div className="workshop-text-block">
        <h1 className={deviceLarge ? "pink right" : "pink"}>
          Join us for our workshops!
        </h1>
        <div>
          We regularly attend repair cafes to offer the Team Repair experience
          for free! Follow us on instagram to hear about our next event near
          you.
        </div>
        <button
          className="follow-button"
          onClick={() => {
            window.open("https://instagram.com/teamrepair");
          }}
        >
          <div>Follow us on Instagram</div>
          <div className="submit-arrow">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.333 6.66669L26.6663 16L17.333 25.3334M5.33301 16H26.6663H5.33301Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default WorkshopBlock;
