import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import WaitlistSignup from "./pages/waitlistSignup";
import Onboard from "./pages/onboard";
import Support from "./pages/support";
import Footer from "./elements/footer";
import Navbar from "./elements/navbar";
import PrepurchaseSignup from "./pages/prepurchaseSignup";
import PostPurchaseSignup from "./pages/postPurchaseSignup";
import StripeRedirect from "./pages/stripeRedirect";
import Download from "./pages/download";
import Returns from "./pages/returns";
import Login from "./pages/login";
import UserDashboard from "./pages/userDashboard";
import UserCheckout from "./pages/userCheckout";
import UserStripeRedirect from "./pages/userStripeRedirect";

function App() {
  const breakpointDesktop = 990;
  const [deviceLarge, setDeviceLarge] = React.useState(
    window.innerWidth > breakpointDesktop
  );
  // const [env, setEnv] = useState<"local" | "stag" | "prod">("local");

  const handleWindowResize = () => {
    setDeviceLarge(window.innerWidth > breakpointDesktop);
    console.log(window.innerWidth > breakpointDesktop);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  function Redirect() {
    useEffect(() => {
      window.location.href = "/";
    }, []);
    return <></>;
  }

  function RedirectToHomepage() {
    useEffect(() => {
      window.location.href = "https://team.repair";
    }, []);
    return <></>;
  }

  const env = window.location.href.includes("localhost:3000")
    ? "local"
    : window.location.href.includes("teamrepair-signup-staging.web.app")
    ? "stag"
    : "prod";

  return (
    <div className="tr-page-cont">
      <Navbar />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <WaitlistSignup
                deviceLarge={deviceLarge}
                confirm={false}
                env={env}
              />
            }
          />
          {/* <Route
            path="/pre-prod"
            element={
              <PrepurchaseSignup
                deviceLarge={deviceLarge}
                env={"prod"}
                pageVersion={1}
              />
            }
          /> */}
          <Route
            path="/pre-stag"
            element={
              <PrepurchaseSignup
                deviceLarge={deviceLarge}
                env={"stag"}
                pageVersion={1}
              />
            }
          />
          <Route
            path="/checkout"
            element={
              <PrepurchaseSignup
                deviceLarge={deviceLarge}
                env={env}
                pageVersion={1}
              />
            }
          />
          <Route
            path="/qr"
            element={
              <WaitlistSignup
                deviceLarge={deviceLarge}
                confirm={false}
                env={env}
              />
            }
          />
          <Route
            path="/download"
            element={<Download deviceLarge={deviceLarge} />}
          />
          <Route
            path="/confirm"
            element={
              <WaitlistSignup
                deviceLarge={deviceLarge}
                confirm={true}
                env={env}
              />
            }
          />
          {/* <Route path="/beta" element={<Onboard deviceLarge={deviceLarge} />} /> */}
          {/* <Route
            path="/create-dev"
            element={<Onboard deviceLarge={deviceLarge} dev={true} />}
          /> */}
          <Route
            path="/finish-setup/:token"
            element={<PostPurchaseSignup deviceLarge={deviceLarge} env={env} />}
          />
          {/* <Route
            path="/finish-setup-prod/:token"
            element={<PostPurchaseSignup deviceLarge={deviceLarge} env={"prod"} />}
          /> */}
          <Route
            path="/payment-confirmation"
            element={<StripeRedirect deviceLarge={deviceLarge} />}
          />
          <Route
            path="/support"
            element={<Support deviceLarge={deviceLarge} />}
          />
          <Route
            path="/return"
            element={<Returns deviceLarge={deviceLarge} />}
          />
          <Route path="/referral" element={<RedirectToHomepage />} />
          <Route
            path="/login"
            element={<Login deviceLarge={deviceLarge} env={env} />}
          />
          <Route
            path="/user"
            element={<UserDashboard deviceLarge={deviceLarge} env={env} />}
          />
          <Route
            path="/user/checkout"
            element={<UserCheckout deviceLarge={deviceLarge} env={env} />}
          />
          <Route
            path="/user/payment-confirmation"
            element={<UserStripeRedirect deviceLarge={deviceLarge} />}
          />
          <Route path="*" element={<Redirect />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
